import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import {ROLES} from '../config/roles'
/**
 * GETS THE CURRENT AUTH TOKEN DATA AND TRANSLATES THE DATA AND THE ROLES
 * @returns USER DATA WITH USER PERMISSIONS IN JSON FORM, IN CASE NO HIGHER ROLE IT RETURNS MANAGER
 */
const useAuth = () => {
    const auth = useAuthUser() 
    
    let isLogged = true
    let isSupervisor = false
    let isAdmin = false
    let status = "Manager"

    if(auth){
        const {id, name, email, roles} = auth

        isSupervisor = roles.includes(ROLES.Supervisor)
        isAdmin = roles.includes(ROLES.Admin)

        if(isSupervisor) status = "Supervisor"
        if(isAdmin) status = "Admin"

        return {isLogged, id, name, email, roles, isSupervisor, isAdmin, status}
    }

    return {
        isLogged: false,
        name:'',
        id: '',
        email: '',
        roles:[],
        isSupervisor,
        isAdmin,
        status
    }
}

export default useAuth;