import { useTheme } from '@emotion/react'
import { Button, Grid, Paper, Typography } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { prettyDate } from '../helper/Time';
import AddIcon from '@mui/icons-material/Add';
import ExpandableList from '../components/ExpandableList';

/**
 * BILLING PAGE
 * 
 * Return the billing page including the overview of the subscribtion and outstanding tokens for the company.
 * 
 * @returns Billing page component
 */
export default function Billing() {
    const theme = useTheme();

    //Invoices log
    const [invoices, setInvoices] = useState([]);

    //Fetched company information
    const [billingInfo, setBillingInfo] = useState({
        account: {
            status: 'OFFLINE',
            activated: '---',
            valid: '---',
        },
        balance: {
            outstanding: 0.00,
            next: '---',
            block: '---'
        }
    })

    //Fetch invoices
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/company/invoices');
                if (response.error) {
                    toast.error(response.error);
                } else {
                    setInvoices(response.data)
                }
            } catch (error) {
                console.error('Error fetching data.', error);
            }
        };

        fetchData();

        // eslint-disable-next-line
    }, []);

    //Fetch billing information
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/company/billing');
                if (response.error) {
                    toast.error(response.error);
                } else {
                    console.log(response.data)
                    setBillingInfo({
                        account: {
                            status: response.data.account.status ? 'ACTIVE' : 'OFFLINE',
                            activated: '---',
                            valid: prettyDate(response.data.account.valid),
                        },
                        balance: {
                            outstanding: response.data.balance.outstanding,
                            next: prettyDate(response.data.balance.next),
                            block: prettyDate(response.data.balance.block)
                        }
                    })
                }
            } catch (error) {
                console.error('Error fetching data.', error);
            }
        };

        fetchData();
    }, [])

    const handlePay = async (invoicId) => {
        try {
            const response = await axios.post(`/company/invoice/pay/${invoicId}`);
            if (response.error) {
                toast.error(response.error);
            } else {
                window.location.reload();
            }
        } catch (error) {
            console.error('Error fetching data.', error);
        }
    };

    /**
     * ONE INVOICE COMPONENT FOR THE INVOICES TABLE
     * 
     * @param {object} params Component parameters 
     * @param {String} params.id Invoice id 
     * @param {Number} params.amount Invoiced amount
     * @param {datetime} params.created Date of creation
     * @param {datetime} params.valid Valid until
     * @param {String} params.link Invoice download link
     * @param {datetime} params.paid Date of payment
     * @returns 
     */
    const Bill = ({ id, amount, created, valid, link, paid }) => {
        return (
            <Paper elevation={3} style={{ borderRadius: 8, width: '100%', marginBottom: 8 }}>
                <Grid container sx={{ padding: 1, paddingLeft: 5, paddingRight: 5 }} alignItems={'center'}>
                    <Grid item xs={4}>
                        <Typography variant="body1" >{id}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" fontWeight='300'>{amount}€</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" fontWeight='300'>{created}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" fontWeight='300'>{valid}</Typography>
                    </Grid>
                    <Grid item xs={2} container justifyContent={'flex-end'}>
                        {Boolean(paid) && (
                            <Button variant="outlined" style={{ marginLeft: '1em' }} href={link} target="_blank">
                                Download
                            </Button>
                        )}
                        {Boolean(!paid) && (
                            <Button variant='contained' style={{ marginLeft: '1em' }} onClick={() => handlePay(id)}  >
                                Pay
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        )
    }

    //Activat membership
    //future TODO - payment needs to be connected
    const handleActivateMembership = async () => {
        try {
            const response = await axios.post('/company/membership/pay');
            if (response.error) {
                toast.error(response.error);
            } else {
                toast.success("Action successful.")
                console.log(response.data)
                window.location.reload();
            }
        } catch (error) {
            console.error(error);
        }
    }

    //Connector for pre-built for sequential show component - matching parameters
    const BillItemConnector = ({ item }) => {
        return (
            <Bill
                key={item.id_bill}
                id={item.bill_number}
                amount={item.amount}
                created={prettyDate(item.creation_date)}
                valid={prettyDate(item.due_date)}
                paid={item.is_paid}
                link={`https://aibraham.tech/api/company/invoice/${item.bill_number}/download/${item.token}`}>
            </Bill>)
    }

    return (
        <div>
            {/* HEADER */}
            <Typography variant='h2' >
                Billing
            </Typography>

            <Grid container spacing={2} mt={2}>
                {/* First Column */}
                <Grid item xs={6}>
                    <Paper elevation={3} style={{ borderRadius: 10, padding: 40, height: '100%' }}>
                        <Typography variant="body1" fontWeight='300'>Account status</Typography>
                        <Typography variant="h3" letterSpacing='2px' color={theme.palette.orangeAccent.main}>{billingInfo.account.status}</Typography>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={6}>
                                <Typography variant="body1">Valid until:</Typography>
                                <Typography variant="body1" fontWeight='300'>{billingInfo.account.valid}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {/*<Typography variant="body1">Activated on:</Typography>
                                <Typography variant="body1" fontWeight='300'>{billingInfo.account.activated}</Typography>*/}
                                <Typography variant='body1' >Activate for 30 days</Typography>
                                <Button variant='text' size='small' startIcon={(<AddIcon />)} onClick={handleActivateMembership}>Activate</Button>
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid>

                {/* Second Column */}
                <Grid item xs={6}>
                    <Paper elevation={3} style={{ borderRadius: 10, padding: 40, height: '100%' }}>
                        <Typography variant="body1" fontWeight='300'>Outstanding balance</Typography>
                        <Typography variant="h3" letterSpacing='2px'>{billingInfo.balance.outstanding.toPrecision(4)}€</Typography>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={6}>
                                <Typography variant="body1">Next bill on:</Typography>
                                <Typography variant="body1" fontWeight='300'>{billingInfo.balance.next}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1">Blocked after:</Typography>
                                <Typography variant="body1" fontWeight='300'>{billingInfo.balance.block}</Typography>

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            {/* INVOICES LOG LABEL */}
            <Typography variant="h5" fontWeight='300' mt={4}>Invoices overview</Typography>

            <Grid container mt={2} sx={{ paddingLeft: 5, paddingRight: 5 }} alignItems={'center'} pb={2}>
                <Grid item xs={4}>
                    <Typography variant="body2" fontWeight='300'>Invoice ID</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body2" fontWeight='300'>Amount</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body2" fontWeight='300'>Created</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body2" fontWeight='300'>Valid</Typography>
                </Grid>
                <Grid item xs={2} container justifyContent={'flex-end'}>
                </Grid>
            </Grid>

            {/* EXPANDABLE INVOICE LOG */}
            <ExpandableList list={invoices} componentType={BillItemConnector} />

        </div >
    )
}
