import React, { useContext, useEffect, useState } from 'react';
import { Paper, Typography, IconButton, Button, Grid, Tooltip, ClickAwayListener, Menu, MenuItem, List } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ShareIcon from '@mui/icons-material/Share';
import { AgentContext } from '../context/AgentContext';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useTheme } from '@emotion/react';
import { prettyDate, timeAgo } from '../helper/Time';

/**
 * FINETUNE PAGE
 * 
 * Shows all tuned or assigned for tune sessions
 * 
 * @returns Finetune page component
 */
export default function Finetune() {

    const [sessions, setSessions] = useState([]);
    const agentContext = useContext(AgentContext);
    const { agentData } = agentContext;

    //Fetch scheduled sessions
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/company/agent/${agentData.id_agent}/finetune/scheduled`,);
                if (response.error) {
                    toast.error(response.error);
                } else {
                    //console.log(response.data)
                    setSessions(response.data)
                }
            } catch (error) {
                console.error('Error fetching data.', error);
            }
        };

        if (agentData.id_agent) fetchData();

        // eslint-disable-next-line
    }, [agentData]);

    /**
     * ONE TUNE ITEM COMPONENT
     * 
     * @param {object} props Component props
     * @param {object} props.id ID of session
     * @param {object} props.modificationDate Last modification date of session
     * @param {object} props.tuneStatus Tune status of the session
     * @param {object} props.publicKey Public key of the session
     * @returns Tune item component
     */
    const TuneItem = ({ id, modificationDate, tuneStatus, publicKey}) => {

        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const theme = useTheme();
        const navigate = useNavigate();
        const agentContext = useContext(AgentContext);
        const { agentData } = agentContext;
        const [tooltip, setTooltip] = useState(false);

        //Handle quickaction menu open
        const handleMenu = (event) => {
            setAnchorEl(event.currentTarget);
        };
        
        //Handle quickaction menu open
        const handleMenuClose = () => {
            setAnchorEl(null);
        };

        //Open session
        const handleClick = () => {
            navigate(`/sessions/${id}`);
        }

        //Close tooltip
        const handleTooltipClose = () => {
            setTooltip(false);
        };

        //Open shared tooltip
        const handleTooltipOpen = async () => {
            const link = `${window.location.origin}/share/session/${publicKey}`
            try {
                await navigator.clipboard.writeText(link);
                setTooltip(true);
            } catch (error) {
                console.error('Error copying link to clipboard:', error);
            }
        };

        //Delete session
        const onDelete = async () => {
            try {
                const response = await axios.delete(`/company/agent/${agentData.id_agent}/session/${id}`);
                if (response.error) {
                    toast.error(response.error);
                } else {
                    toast.success("Action successful.")
                    window.location.reload();
                }
            } catch (error) {
                console.error(error);

            }
        }

        //Change tune status scheduled or not scheduled
        const handleTuneStatus = async () => {
            try {
                const changedStatus = Number(tuneStatus) === 0 ? 1 : 0;
                const response = await axios.put(`/company/agent/${agentData.id_agent}/session/${id}/finetune`, { tuneStatus: changedStatus });
                if (response.error) {
                    toast.error(response.error);
                } else {
                    toast.success("Session modified.")
                    window.location.reload();
                }
            } catch (error) {
                console.error(error);
            }
        }

        return (
            <Paper elevation={3} style={{ borderRadius: 8, width: '100%', marginBottom: 8 }}>
                <Grid container sx={{ padding: 1, paddingLeft: 3, paddingRight: 3 }} alignItems={'center'}>

                    <Grid item xs={4}>
                        <Typography variant="body1" >{id}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="body1" >{modificationDate}</Typography>
                    </Grid>

                    {/* QUICK ACTIONS */}
                    <Grid item xs={4} container justifyContent={'flex-end'}>

                        {/* FINETUNE ACTION */}
                        <Tooltip title='Fine tune'>
                            <IconButton onClick={handleTuneStatus}>
                                <PsychologyIcon sx={tuneStatus ? { color: (theme) => theme.palette.orangeAccent.main } : {}} />
                            </IconButton>
                        </Tooltip>

                        {/* SHARE ACTION */}
                        <ClickAwayListener onClickAway={handleTooltipClose}>
                            <Tooltip
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                onClose={handleTooltipClose}
                                open={tooltip}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title="Copied to clipboard"
                            >
                                <IconButton onClick={() => handleTooltipOpen(id)}>
                                    <ShareIcon />
                                </IconButton>
                            </Tooltip>
                        </ClickAwayListener>

                        {/* MORE ACTIONS */}
                        <IconButton onClick={handleMenu}>
                            <MoreVertIcon />
                        </IconButton>

                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleMenuClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={onDelete}>Delete</MenuItem>
                        </Menu>

                        <Button variant="outlined" style={{ marginLeft: '1em' }} onClick={handleClick}>
                            Open
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        );
    }

    //Tune table label
    const Label = () => {
        return (
            <Grid container mt={2} sx={{ paddingLeft: 3, paddingRight: 3 }} alignItems={'center'}>
                <Grid item xs={4}>
                    <Typography variant="body2" fontWeight='300'>Session ID</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="body2" fontWeight='300'>Last Modified</Typography>
                </Grid>
                <Grid item xs={4} pl={23}>
                    <Typography variant="body2" fontWeight='300'>Actions</Typography>
                </Grid>
            </Grid>
        )
    }

    //Tune table footer description component
    const Description = () => {
        return (
            <Grid item xs={12} mt={4}>
                <Typography variant='body2' fontWeight={300} align='justify' color={'#807d7d'}>
                    The tuned sections show you an overview of the currently used data in your fine tuned agent. If you don’t have your agent fine tuned yet, create or tag sessions to collect the needed tuning data and press the button above to fine tune. Fine tuning your agent helps to have more precise agents with better agent knowledge while it helps to reduce costs too.
                </Typography>
            </Grid>
        )
    }

    return (
        <div>
            {/*HEADER*/}
            <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                <Grid item xs={6}>
                    <Typography variant='h2' align="left">Finetune</Typography>
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end" alignItems="bottom">
                    <Button disabled variant="contained" size="large">Tune</Button>
                </Grid>
            </Grid>

            {/* SCHEDULED FOR TUNE */}
            <Typography variant="h5" fontWeight='300' mt={4}>Sessions scheduled</Typography>
            <Label />
            <List>
                {
                    sessions.map(session => (
                        <TuneItem
                            key={session.id_session}
                            cost={session.cost}
                            id={session.id_session}
                            modificationDate={timeAgo(session.last_modified)}
                            opened={prettyDate(session.opened)}
                            tags={session.tags}
                            isTicket={session.is_ticket}
                            tuneStatus={session.finetune}
                            publicKey={session.public_key}
                        ></TuneItem>
                    )
                    )
                }
            </List>

            {/* BLOCKED TUNED ACTIONS */}
            <Typography variant="h5" fontWeight='300' mt={4} mb={1}>Sessions in tune</Typography>
            <Label></Label>
            <Paper elevation={3} style={{ borderRadius: 8, width: '100%', marginBottom: 8, marginTop: 8 }}>
                <Grid container sx={{ padding: 1, paddingLeft: 3, paddingRight: 3 }} alignItems={'center'}>
                    <Grid item xs={9}>
                        <Typography variant="body1" fontWeight='300'>Coming soon...</Typography>
                    </Grid>
                    <Grid item xs={3} container justifyContent={'flex-end'}>
                        <Button variant="outlined" style={{ marginLeft: '1em' }} disabled>
                            Open
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            <Description></Description>

        </div>
    )
}
