import { FormControl, Grid, IconButton, InputLabel, List, Paper, Select, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react';
import { TextField, Button, MenuItem } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { AgentContext } from '../context/AgentContext';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

/**
 * KNOWLEDGE MANAGEMENT COMPONENT
 * 
 * Displays the details of the knowledge if it already existed
 * Displays the form to fill out when creating a new knowledge
 * 
 * @param {object} props Component props
 * @param {Boolean} props.isNew Defines the form of the component, whether we are adding a new knowledge or managing an old one
 * @returns 
 */
const KnowledgeForm = ({ isNew }) => {

    //Path parameter with knowledge ID
    const { id } = useParams();
    const navigate = useNavigate();
    const agentContext = useContext(AgentContext);
    const { agentData } = agentContext;

    //Parameters for functional knowledge
    const [parameters, setParameters] = useState([]);

    //Patemeter window open or not
    const [openParameter, setOpenParameter] = useState(false)

    //Status indicator
    const statuses = [
        { value: true, label: 'Active', icon: <CircleIcon fontSize='small' style={{ color: 'green', }} /> },
        { value: false, label: 'Offline', icon: <CircleIcon fontSize='small' style={{ color: 'gray' }} /> },
    ];

    //Category
    const options = [
        { value: false, label: 'Informational' },
        { value: true, label: 'Functional' },
    ]

    //Form data
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        isFunction: false,
        status: true,
    });

    /**
     * Constructing JSON format from the input form
     * @returns JSON acceptable by the backend and OpenAI
     */
    const constructJson = () => {

        var constructedParams = {}

        //Putting the parameters in a correct form
        for (let index = 0; index < parameters.length; index++) {
            const parameter = parameters[index];

            constructedParams[parameter.name.replace(/ /g, "_")] = {
                type: parameter.type,
                description: parameter.description
            }
        }

        return {
            type: "function",
            function: {
                name: formData.title.replace(/ /g, "_"),
                description: formData.description,
                parameters: {
                    type: "object",
                    properties: constructedParams
                },
            },
        }
    }

    /**
     * Fetch the agents knowledge and divide it into informational and functional
     */
    useEffect(() => {

        const fetch = async () => {
            try {
                const { data } = await axios.get(`/company/agent/${agentData.id_agent}/knowledge/${id}`)
                if (data.error) {
                    toast.error(data.error);
                } else {
                    if (Boolean(data.is_function)) {
                        const content = JSON.parse(data.content);
                        setFormData({
                            title: data.title,
                            description: content.function.description,
                            isFunction: Boolean(data.is_function),
                            status: Boolean(data.is_active)
                        });
                        const mappedProperties = Object.entries(content.function.parameters.properties).map(([key, value]) => ({ ...value, name: key }));
                        setParameters(mappedProperties);
                    } else
                        setFormData({
                            title: data.title,
                            description: data.content,
                            isFunction: Boolean(data.is_function),
                            status: Boolean(data.is_active)
                        });
                }
            } catch (error) {
                console.log(error);
            }
        }

        if (!isNew && id && agentData.id_agent) fetch();
    }, [agentData.id_agent, id, isNew])

    //Manage form change
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    //Save or create Knowledge
    const handleSubmit = async (e) => {
        e.preventDefault()

        try {

            var body = { ...formData };

            if (formData.isFunction) {
                body.description = JSON.stringify(constructJson());
            } else {
                body.description = formData.description;
            }

            var data = null;
            if (isNew)
                data = { data } = await axios.post(`/company/agent/${agentData.id_agent}/knowledge`, body)
            else
                data = { data } = await axios.put(`/company/agent/${agentData.id_agent}/knowledge/${id}`, body)
            if (data.error) {
                toast.error(data.error);
            } else {
                setFormData({
                    title: '',
                    description: '',
                    isFunction: 0,
                    status: true,
                });
                if (isNew)
                    toast.success('New knowledge added.');
                else
                    toast.success('Knowledge updated.');
                navigate('/knowledge')
            }
        } catch (error) {
            console.log(error);
        }

    };

    //Delete parameter
    const handleRemoveParameter = (index) => {
        const updatedParameters = parameters.filter((_, i) => i !== index);
        setParameters(updatedParameters);
    };

    /**
     * ADD PARAMETER FORM
     * 
     * Displays an openable parameter adding input form for the functional knowledge
     * 
     * @returns component for adding a parameter
     */
    const ParameterForm = () => {
        const [newParameter, setNewParameter] = useState({ name: '', description: '', type: 'string' });

        //Handle parameter form
        const handleNewParamChange = (event) => {
            const { name, value } = event.target;
            setNewParameter({ ...newParameter, [name]: value });
        }

        //Add new parameter
        const handleAddParameter = () => {
            setParameters([...parameters, newParameter]);
            setNewParameter({ name: '', description: '', type: 'string' });
            setOpenParameter(false)
        };

        return (
            <div>
                <Grid container >
                    <Grid item xs={10} container alignItems={'center'}>
                        <Typography variant='body1'>Add new parameter</Typography>
                    </Grid>
                    <Grid item xs={2} container justifyContent={'flex-end'}>
                        <IconButton onClick={handleParameterWindow}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>

                </Grid>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        label="Parameter name"
                        name="name"
                        value={newParameter.name}
                        sx={{ maxWidth: '49%', marginRight: '0.5em' }}
                        onChange={handleNewParamChange}
                    />
                    <FormControl fullWidth variant="outlined" margin="normal" sx={{ maxWidth: '49%' }}>
                        <InputLabel>Type</InputLabel>
                        <Select
                            label="Type"
                            name="type"
                            value={newParameter.type}
                            onChange={handleNewParamChange}
                        >
                            <MenuItem value="string">String</MenuItem>
                            <MenuItem value="number">Number</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <TextField
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    label="Description"
                    name="description"
                    value={newParameter.description}
                    onChange={handleNewParamChange}
                />
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <Button sx={{ marginTop: 2 }} variant="contained" color="primary" onClick={handleAddParameter}>
                        Add Parameter
                    </Button>
                </div>
            </div>
        )
    }

    //Open/close parameter window
    const handleParameterWindow = () => {
        setOpenParameter(!openParameter)
    }

    /**
     * FUNCTIONAL KNOWLEDGE PARAMETERS
     * 
     * Display parameterform and display added parameters
     * 
     * @returns component
     */
    const ParameterSection = () => {
        return (
            <>
                <Paper elevation={3} style={{ borderRadius: 4, width: '100%', marginTop: 15, padding: '0.8em 1.5em' }}>
                    {
                        //Display parameter form in case its opened in functional knowledge
                        openParameter && (<ParameterForm />)
                    }
                    {
                        //Display option to add a parameter in functional knowledge
                        !openParameter && (
                            <Grid container >
                                <Grid item xs={10} container alignItems={'center'}>
                                    <Typography variant='body1'>Add new parameter</Typography>
                                </Grid>
                                <Grid item xs={2} container justifyContent={'flex-end'}>
                                    <IconButton onClick={handleParameterWindow}>
                                        <AddIcon />
                                    </IconButton>
                                </Grid>

                            </Grid>
                        )
                    }

                </Paper>

                <List>
                    {
                        //List parameters if available 
                        parameters.map((parameter, index) => (
                            <Paper key={index} style={{ borderRadius: 4, marginTop: 16, padding: 16, paddingLeft: 20 }}>
                                <Grid container >
                                    <Grid item xs={10} container alignItems={'center'}>
                                        <Typography variant='h6'>Parameter: {parameter.name}</Typography>
                                    </Grid>
                                    <Grid item xs={2} container justifyContent={'flex-end'}>
                                        <IconButton onClick={() => handleRemoveParameter(index)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>

                                </Grid>
                                <Typography variant="subtitle1">Description: {parameter.description}</Typography>
                                <Typography variant="subtitle1">Type: {parameter.type}</Typography>
                            </Paper>
                        ))}
                </List>
            </>)
    }

    return (
        <>
            <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                <Grid item xs={6}>
                    <Typography variant='h2' align="left">{isNew ? "Add knowledge" : "Edit knowledge"}</Typography>
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end" alignItems="bottom">
                    <Button type='submit' onClick={handleSubmit} variant="contained" size="large">"Save"</Button>
                </Grid>
            </Grid>
            <Grid container spacing={2} >
                <Grid item xs={6} container>
                    <form style={{ width: '100%' }}>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            select
                            label="Set status"
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                            sx={{ maxWidth: '49%', marginRight: '0.5em' }}
                        >
                            {statuses.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {/*<ListItemIcon>{option.icon}</ListItemIcon>*/}
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            select
                            label="Choose structure"
                            name="isFunction"
                            value={formData.isFunction}
                            onChange={handleChange}
                            sx={{ maxWidth: '49%' }}
                            disabled={isNew ? false : true}
                        >
                            {options.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            label={formData.isFunction ? "Function name" : "Title"}
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            label={formData.isFunction ? "Describe when to use the function" : "Describe knowledge"}
                            multiline
                            minRows={4}
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            inputProps={{ maxLength: 3000 }}
                        />
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '-2em' }}>
                            <Typography variant="body2" fontWeight={'200'} style={{ alignSelf: 'flex-end', marginRight: 8 }}>
                                {formData.description.length} / 3000
                            </Typography>
                        </div>

                        {
                            formData.isFunction && (<ParameterSection />)
                        }

                    </form>
                </Grid>
                <Grid item xs={6} container>
                    <Grid item xs={12}>
                        <Paper elevation={3} style={{ borderRadius: 4, padding: '2em', height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Typography variant='h4' mb={2}>{formData.title}</Typography>
                            <Typography variant='body2' style={{ whiteSpace: 'pre-wrap' }}>{formData.isFunction ? JSON.stringify(constructJson(), null, "\t") : formData.description}</Typography>
                        </Paper>
                    </Grid>

                </Grid>

            </Grid>
        </>
    );
};

export default KnowledgeForm;