import React, { useContext, useEffect, useState } from 'react'
import { AgentContext } from '../context/AgentContext';
import { Switch, Typography, Paper, Button, Grid, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import toast from 'react-hot-toast';
import axios from 'axios';

/**
 * AGENT'S OPPTIONS PAGE
 * 
 * Displays page for detailed agent configuration
 * 
 * @returns Agent's options page
 */
export default function Options() {

    const agentContext = useContext(AgentContext);
    const { agentData } = agentContext;
    const [settings, setSettings] = useState({error_message:""});

    //Read data from agent context
    useEffect(() => {
        setSettings({...agentData, error_message: agentData.error_message ? agentData.error_message : ""});
    }, [agentData])

    //Handle setting changes
    const handleChange = (e) => {
        const { name, checked } = e.target;
        setSettings({ ...settings, [name]: checked });
    };

    //Post save settings
    const saveUpdates = async () => {
        try {
            const { data } = await axios.put(`/company/agent/${agentData.id_agent}`, settings)
            if (data.error) {
                toast.error(data.error);
            } else {
                agentContext.setAgentData(settings);
                toast.success('Settings saved.');
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * ONE OPTION ITEM COMPONENT
     * 
     * One setting option
     * 
     * @param {object} props Component props 
     * @param {String} props.title 
     * @param {Boolean} props.isActive 
     * @param {Function} props.onChange 
     * @param {String} props.name 
     * @returns item component
     */
    const OptionItem = ({ title, isActive, onChange, name }) => {

        //ACTIVE OR DEACTIVATED
        const switchColor = isActive ? 'lightgreen' : 'gray';

        return (
            <Paper elevation={3} style={{ borderRadius: 8, width: '100%', marginBottom: 8 }}>
                <Grid container sx={{ padding: 1, paddingLeft: 3, paddingRight: 3 }} alignItems={'center'}>
                    <Grid item xs={1}>
                        <CheckIcon style={{ color: switchColor }} />
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body1">{title}</Typography>
                    </Grid>
                    <Grid item xs={2} container justifyContent={'flex-end'}>
                        <Switch
                            name={name}
                            checked={Boolean(isActive)}
                            onChange={onChange}
                            color="default"
                            style={{ color: switchColor }}
                        />
                    </Grid>
                </Grid>
            </Paper>

        );
    };

    return (
        <div>
            {/* HEADER WITH PARAMETERS */}
            <Grid container alignItems="center" justifyContent="space-between" spacing={2} mb={2}>
                <Grid item xs={4}>
                    <Typography variant='h2' align="left">Settings</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='body1'><b>Agent name:</b> {agentData.name}</Typography>
                    <Typography variant='body2'><b>Secret key:</b> {agentData.secret_key}</Typography>
                </Grid>
                <Grid item xs={4} container justifyContent="flex-end" alignItems="bottom">
                    <Button disabled={settings === agentData} variant="contained" size="large" onClick={saveUpdates} >Save</Button>
                </Grid>
            </Grid>

            {/* LIST OF SETTINGS */}
            <Grid container>
                <Grid item xs={6} container >
                    <OptionItem title="Turn ON/OFF agent" isActive={settings.is_active} name={"is_active"} onChange={handleChange} ></OptionItem>
                    <OptionItem title="Accept e-mail quarries" isActive={settings.accept_email} name={"accept_email"} onChange={handleChange} ></OptionItem>
                    <Grid item mt={2} xs={12}>
                        <OptionItem title="Custom error message" isActive={Boolean(settings.is_custom_error)} name={"is_custom_error"} onChange={handleChange} ></OptionItem>
                        <TextField
                            sx={{ marginBottom: 1 }}
                            fullWidth
                            label="Custom error message"
                            variant="outlined"
                            value={settings.error_message}
                            onChange={(e) => setSettings({ ...settings, error_message: e.target.value })} />
                    </Grid>
                </Grid>
            </Grid>

        </div>
    )
}
