
import { AppBar, Toolbar, Typography, Avatar, Button, Box, Grid } from '@mui/material';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import useAuth from '../hooks/useAuth';
import { useContext, useEffect, useState } from 'react';
import { AgentContext } from '../context/AgentContext';
import axios from 'axios';
import { useTheme } from '@emotion/react';

/**
 * HORIZONTAL MENU BAR FOR THE MAIN MENU
 * 
 * For not logged in -> Login & Register
 * For logged in -> Company account data and user profile
 * 
 * @returns Horizontal menu bar component
 */
function Menubar() {

  const state = useAuth();
  const navigate = useNavigate();
  const signOut = useSignOut();
  const agentContext = useContext(AgentContext);
  const { agentData } = agentContext;
  const theme = useTheme();

  //Company billing details - token turnover
  const [turnover, setTurnover] = useState({ cost: 0, turnover: 0 })

  //Account status information
  const [accountStatus, setAccountStatus] = useState(false)

  //Handle react-auth-kit signout
  const handleSignOut = () => {
    signOut();
    navigate('/');
    toast.success('Signed out.');
    window.location.reload()
  }

  //Fetch the needed billing information
  useEffect(() => {

    const fetchData = async () => {
      try {
        const { data } = await axios.get(`/company/billing`);
        if (data.error) {
          toast.error(data.error);
        } else {
          setAccountStatus(Boolean(data.account.status));
          console.log(data)
          setTurnover({ cost: data.balance.outstanding, turnover: data.balance.turnover });
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (agentData.company_id) {
      fetchData(); // Fetch data immediately on mount
      const intervalId = setInterval(fetchData, 15000); // Fetch data every 5 seconds
      return () => clearInterval(intervalId); // Cleanup function to clear interval on unmount
    }
  }, [agentData.company_id]);

  return (
    <nav>
      <AppBar position="static" sx={{ backgroundColor: '#121212' }} elevation={0}>
        <Toolbar >

          {/* FOR LOGGEN IN USER - SHOW ACCOUNT DATA AND PROFILE */}
          {state.isLogged ? (
            <>
              {/* ACCOUNT STATUS */}
              <Grid container flex={1} flexDirection={"column"} >
                <Typography variant='body1' lineHeight={1}><b>Account status</b></Typography>
                <a href="/billing" style={{textDecoration: 'none', color: 'inherit', cursor: 'pointer'}}>
                  <Typography variant='body2' lineHeight={1} color={theme.palette.orangeAccent.main}>{accountStatus ? "ACTIVE" : "OFFLINE" }</Typography>
                </a>
              </Grid>

              {/* TOKEN TURNOVER */}
              <Grid container flex={1} flexDirection={"column"} >
                <Typography variant='body1' lineHeight={1}><b>Monthly turnover</b></Typography>
                <Typography variant='body2' lineHeight={1}>{turnover.turnover} Tokens = {turnover.cost.toPrecision(3)}€</Typography>
              </Grid>

              {/* USER PROFILE */}
              <Grid container sx={{ maxWidth: '350px' }} direction="row" alignItems="center" justifyContent="right">
                <Grid container item alignItems="center" justifyContent="right" sx={{ maxWidth: '150px' }} mr={2}>
                  <Grid item>
                    <Typography gutterBottom variant="subtitle1" mb={-1.5} mr={0.5} >
                      {state.email}
                    </Typography>
                  </Grid>
                  <Grid item container direction="row" alignItems="center" justifyContent="right">
                    <Grid item>
                      <Button color="inherit" onClick={() => navigate('/profile')} size="small">
                        Profile
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button color="inherit" onClick={() => handleSignOut()} size="small">
                        Sign Out
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Avatar>{state.status[0]}</Avatar>
                </Grid>
              </Grid>
            </>
          ) : (

            //{/* FOR NOT LOGGED IN USER - SHOW LOGIN & REGISTER BUTTONS */}
            <Grid container alignItems="center" justifyContent="right">
              <Box>
                <Button color="inherit" onClick={() => navigate('/login')} sx={{marginRight:1}} >Log In</Button>
                <Button variant='contained' color="inherit" onClick={() => navigate('/register')} sx={{backgroundColor: theme.palette.orangeAccent.main, color:"black"}}>Register</Button>
              </Box>
            </Grid>
          )}
        </Toolbar>
      </AppBar>
    </nav>
  );
}

export default Menubar;