import React, { useContext, useEffect, useState } from 'react';
import { Typography, Paper, IconButton, Button, Grid, List, MenuItem, Menu } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { AgentContext } from '../context/AgentContext';
import axios from 'axios';
import { timeAgo } from '../helper/Time';
import toast from 'react-hot-toast';

/**
 * KNOWLEDGE PAGE
 * 
 * Shows all informational and functional knowledge added to the agent
 * 
 * @returns knowledge page component
 */
export default function Knowledge() {

  const navigate = useNavigate();
  const agentContext = useContext(AgentContext);
  const { agentData } = agentContext;

  //All knowledge
  const [knowledges, setKnowledges] = useState([]);

  /*
   * FETCH ALL KNOWLEDGE
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/company/agent/${agentData.id_agent}/knowledge`,);
        setKnowledges(response.data);
      } catch (error) {
        console.error('Error fetching data.', error);
      }
    };

    if (agentData.id_agent) fetchData();

    // eslint-disable-next-line
  }, [agentData]);

  /**
   * TABLE LABELS
   * @returns labels component
   */
  const Label = () => {
    return (
      <Grid container mt={2} sx={{ paddingLeft: 3, paddingRight: 3 }} alignItems={'center'}>
        <Grid item xs={0.5}>
          <Typography variant="body2" fontWeight='300'>Status</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="body2" fontWeight='300'>Title</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography variant="body2" fontWeight='300'>Last Modified</Typography>
        </Grid>
        <Grid item xs={5} container justifyContent={'flex-end'} pr={13}>
          <Typography variant="body2" fontWeight='300'>Actions</Typography>
        </Grid>
      </Grid>
    )
  }

  /**
   * KNOWLEDGE ELEMENT
   * 
   * One line of the table representing one defined knowledge
   * 
   * @param {object} props Component props
   * @param {int} props.id Knowledge ID
   * @param {string} props.title Knowledge Title
   * @param {boolean} props.isActive Knowledge status
   * @param {datetime} props.modificationDate Knowledge last modification date
   */
  const KnowledgeItem = ({ id, title, isActive, modificationDate }) => {

    //Color for illustrating whether its active
    const switchColor = isActive ? '#4CBB17' : 'gray';
    const navigate = useNavigate();

    //Vertical options menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const agentContext = useContext(AgentContext);
    const { agentData } = agentContext;

    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    //Delete knowledge
    const onDelete = async () => {
      try {
        const { data } = await axios.delete(`/company/agent/${agentData.id_agent}/knowledge/${id}`);
        if (data.error) {
          toast.error(data.error);
        } else {
          toast.success("Action successful.")
          window.location.reload();
        }
      } catch (error) {
        console.error(error);

      }
    }

    //Open knowledge
    const handleClick = () => {
      navigate(`/knowledge/${id}`);
    }

    return (
      <Paper elevation={3} style={{ borderRadius: 8, width: '100%', marginBottom: 8 }}>
        <Grid container sx={{ padding: 1, paddingLeft: 3, paddingRight: 3 }} alignItems={'center'}>
          <Grid item xs={0.5}>
            <CircleIcon fontSize='small' style={{ color: switchColor }} />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body1" >{title}</Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography variant="body1" >{modificationDate}</Typography>
          </Grid>
          <Grid item xs={5} container justifyContent={'flex-end'}>

            <IconButton onClick={handleMenu}>
              <MoreVertIcon />
            </IconButton>
            <IconButton onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={onDelete}>Delete</MenuItem>
            </Menu>
            <Button variant="outlined" style={{ marginLeft: '1em' }} onClick={handleClick}>
              Open
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  //Add new knowledge
  const handleAddNew = () => {
    navigate('/knowledge/new');
  }

  /**
   * FOOTER HELP DESCRIPTION COMPONENT
   * @returns footer component
   */
  const Description = () => {
    return (
      <Grid item xs={12} mt={4}>
        <Typography variant='body2' fontWeight={300} align='justify' color={'#807d7d'}>
          In this section you are able to add modify or turn off existing knowledge sources. Knowledge serves for our agent as a base knowledge of your products and company. You should add materials of which the agent should be prepared to answer questions and help. Knowledge is transferred to every session at their launch. You are able do define custom functions which may be used by the agent. The agent returns the call and the function needs to be handled by your front-end implementation.
        </Typography>
      </Grid>
    )
  }

  return (
    <div>
      <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
        <Grid item xs={6}>
          <Typography variant='h2' align="left">Knowledge</Typography>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end" alignItems="bottom">
          <Button variant="contained" size="large" onClick={handleAddNew}>Add New</Button>
        </Grid>
      </Grid>

      {/*INFORMATIONAL KNOWLEDGE LIST */}
      <Typography variant="h5" fontWeight='300' mt={4} mb={1}>Informational</Typography>
      <Label />
      <List>
        {
          knowledges.filter(knowledge => { return !Number(knowledge.is_function) }).map(knowledge => (
            <KnowledgeItem
              key={knowledge.id_knowledge}
              id={knowledge.id_knowledge}
              title={knowledge.title}
              isActive={knowledge.is_active}
              modificationDate={timeAgo(knowledge.last_modified)}
            ></KnowledgeItem>
          )
          )
        }
      </List>

      {/*FUNCTIONAL KNOWLEDGE LIST */}
      <Typography variant="h5" fontWeight='300' mt={4} mb={1}>Functional</Typography>
      <Label />
      <List>
        {
          knowledges.filter(knowledge => { return Number(knowledge.is_function) }).map(knowledge => (
            <KnowledgeItem
              key={knowledge.id_knowledge}
              id={knowledge.id_knowledge}
              title={knowledge.title}
              isActive={knowledge.is_active}
              modificationDate={timeAgo(knowledge.last_modified)}
            ></KnowledgeItem>
          )
          )
        }
      </List>

      <Description></Description>
    </div>
  )
}
