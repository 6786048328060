import React, { useContext, useEffect } from 'react';
import { ButtonBase, Grid, Paper, Typography } from '@mui/material';
import { AgentContext } from '../context/AgentContext';
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { useState } from "react";
import { Line } from 'react-chartjs-2';
import LaunchIcon from '@mui/icons-material/Launch';
import axios from 'axios';
import toast from 'react-hot-toast';
import { prettyDate } from '../helper/Time';
import { useNavigate } from 'react-router-dom';

/**
 * LINE CHART - NEW SESSIONS IN THE LAST 7 DAYS
 * 
 * 
 * @param {object} props Component props
 * @param {object} props.data Contains information about 1 day for the graph 
 * @returns 
 */
function TicketChart({data}) {
    Chart.register(CategoryScale);

    const chartData = {
        labels: data.map((single) => prettyDate(single.date) ),//['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'],
        datasets: [
            {
                label: "Users Gained ",
                data: data.map((single) => single.opened_sessions),
                backgroundColor: [
                    "gray"
                ],
                borderColor: 'gray',
                pointStyle: 'circle',
                pointRadius: 6,
                pointHoverRadius: 15,
                borderWidth: 2
            }
        ]
    };

    function LineChart() {
        return (
            <div style={{ position: 'relative', maxHeight: '350px', width: '100%' }}>
                <Typography align='right' variant='h6'>New sessions in the last 7 days</Typography>
                <Line
                    data={chartData}
                    options={{
                        responsive: true,
                        plugins: {
                            legend: {
                                display: false
                            }

                        },
                        maintainAspectRatio: false,
                    }}
                />
            </div>
        );
    }

    return (
        <LineChart />
    );
}


/**
 * HOME PAGE
 * 
 * Contains a linechart with some statistics, including quick access buttons
 * 
 * @returns home page component
 */
export default function Home() {

    const agentContext = useContext(AgentContext);
    const navigate = useNavigate();

    //Profile stats
    const [homeData, setHomeData] = useState({
        total_session: 0,
        total_tickets: 0,
        graph: []});
    const { agentData } = agentContext;

    //If no agent is selected, no data to show -> show quickstart guide
    useEffect(() => {
        if( !(agentData && agentData.id_agent) ) navigate('/start');
        // eslint-disable-next-line
    }, [agentData]);

    //Fetch information
    useEffect(() => {
        const fetchData = async () => {
            try {
                const {data} = await axios.get(`/company/agent/${agentData.id_agent}/home`,);
                if (data.error) {
                    toast.error(data.error);
                } else {
                    console.log(data.graph)
                    setHomeData(data)
                }
            } catch (error) {
                console.error('Error fetching data.', error);
            }
        };

        if (agentData.id_agent) fetchData();

        // eslint-disable-next-line
    }, [agentData]);

    return (
        <>
            <Typography variant='h2'>Home</Typography>
            <Grid container >
                <Grid item xs={12}>
                    <TicketChart data={homeData.graph}></TicketChart>
                </Grid>
                <Grid item xs={12} container spacing={2} mt={5}>
                    {/* Showcasing all available sessions */}
                    <Grid item xs={6}>
                        <Paper elevation={3} sx={{ padding: 4 }}>
                            <Grid container>
                                <Grid item xs={7}>
                                    <Typography variant='h5'>
                                        Total sessions
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='h5' align='right'>
                                        {homeData.total_session}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    {/* Showcasing all available tickets */}
                    <Grid item xs={6}>
                        <Paper elevation={3} sx={{ padding: 4 }}>
                            <Grid container>
                                <Grid item xs={7}>
                                    <Typography variant='h5'>
                                        Total tickets
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant='h5' align='right'>
                                        {homeData.total_tickets}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={2} mt={1}>
                    {/* Tickets quick action*/}
                    <Grid item xs={3}>
                        <ButtonBase onClick={()=>{navigate('/tickets')}} sx={{ width: '100%' }}>
                            <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>
                                <Grid container justifyContent={'space-between'}>
                                    <Typography variant='h6'>Tickets</Typography>
                                    <LaunchIcon fontSize='small'></LaunchIcon>
                                </Grid>
                            </Paper>
                        </ButtonBase>
                    </Grid>
                    {/* Sessions quick action*/}
                    <Grid item xs={3}>
                        <ButtonBase onClick={()=>{navigate('/sessions')}} sx={{ width: '100%' }}>
                            <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>
                                <Grid container justifyContent={'space-between'}>
                                    <Typography variant='h6'>Sessions</Typography>
                                    <LaunchIcon fontSize='small'></LaunchIcon>
                                </Grid>
                            </Paper>
                        </ButtonBase>
                    </Grid>
                    {/* Knowledge quick action*/}
                    <Grid item xs={3}>
                        <ButtonBase onClick={()=>{navigate('/knowledge')}} sx={{ width: '100%' }}>
                            <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>
                                <Grid container justifyContent={'space-between'}>
                                    <Typography variant='h6'>Knowledge</Typography>
                                    <LaunchIcon fontSize='small'></LaunchIcon>
                                </Grid>
                            </Paper>
                        </ButtonBase>
                    </Grid>
                    {/* Settings quick action*/}
                    <Grid item xs={3}>
                        <ButtonBase onClick={()=>{navigate('/settings')}} sx={{ width: '100%' }}>
                            <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>
                                <Grid container justifyContent={'space-between'}>
                                    <Typography variant='h6'>Settings</Typography>
                                    <LaunchIcon fontSize='small'></LaunchIcon>
                                </Grid>
                            </Paper>
                        </ButtonBase>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}