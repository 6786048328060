import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, ClickAwayListener, Container, Fab, Grid, IconButton, ListItem, Paper, TextField, Tooltip, Typography } from '@mui/material';
import { Send as SendIcon, Close as CloseIcon } from '@mui/icons-material';
import toast from 'react-hot-toast';
import axios from 'axios';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import ShareIcon from '@mui/icons-material/Share';
import LinearProgress from '@mui/material/LinearProgress';

/**
 * SINGLE MESSAGE COMPONENT
 * 
 * Handles a single message object.
 * Used for message windows.
 * 
 * @param {object} props Component props 
 * @param {object} props.message Message containing sender type and message content 
 * @returns Single message component
 */
const MessageBox = ({ message }) => {

    //Sender types
    const SENDER = {
        agent: "assistant",
        user: "user"
    }

    //DUMMY SUCCESS RESPONDER FOR CUSTOM FUNCTIONS
    const handleFunction = async () => {

        //OUTPUT ACCEPTED BY THE BACKEND
        const output = {
            threadId: message.threadId,
            runId: message.runId,
            output: [{
                tool_call_id:
                    message.id,
                output: JSON.stringify({ success: true })
            }]
        };

        try {
            const result = await axios.post('/public/session/call/output', output); //REGISTER OUTPUT
        } catch (error) {
            console.log(error)
        }
    }

    //CUSTOM FUNCTION HANDLING!
    if (message.type === 'function') { // Check whether a function is called

        message.content = 'Calling function, please wait.' // Letting the user know when it gets displayed
        message.role = SENDER.agent // Setting up agent as the sender

        //CHECK CALLED FUNCTION AND CALL THE RIGHT HANDLING
        handleFunction();
    }

    return <Box
        sx={{
            display: 'flex',
            flexDirection: message.role === SENDER.user ? 'row-reverse' : 'row',
            alignItems: 'flex-start',
            mb: 1, ml: 1, mr: 1
        }}>
        <Box
            sx={{
                backgroundColor: message.role === SENDER.user ? 'primary.main' : '#f0f0f0',
                color: message.role === SENDER.user ? '#000' : '#000',
                borderRadius: '10px',
                py: 1, px: 2,
                maxWidth: '70%',
                wordWrap: 'break-word',
            }}>
            <Typography variant="body2">{message.content}</Typography>
        </Box>
    </Box>
}

/**
 * CHAT WINDOW FOR AGENT INTEGRATION
 * 
 * Features:
 *  - Makes possible the communication between agent and client.
 *  - Import and export session.
 *  - Share session for later.
 * 
 * @param {object} props Component props 
 * @param {String} props.agentKey Agent secret key for integration 
 * @returns Floating chat window component
 */
const ChatWindow = ({ agentKey }) => {

    //All messages with initial message
    const [messages, setMessages] = useState([
        {
            role: "assistant",
            type: "message",
            content: "Hi! Im Aibraham, how can I help you?"
        }
    ]);

    //New message input
    const [newMessage, setNewMessage] = useState('');

    //Is the user waiting for agent reply?
    const [isWaiting, setIsWaiting] = useState(false)

    //Chat window status
    const [chatOpen, setChatOpen] = useState(false);

    //Chat window scroll status
    const messagesEndRef = useRef(null);

    //Currently opened session
    const [sessionId, setSessionId] = useState(null);

    //Is the last message the client?
    const [isLastUser, setIsLastUser] = useState(false);

    //Import session form state
    const [sessionInput, setSessionInput] = useState(false);

    //Import session form data
    const [newSessionId, setNewSessionId] = useState('');

    //Share session tooltip state
    const [tooltip, setTooltip] = useState(false);

    const isLastMessageUser = () => {
        const lastItem = messages.length > 0 ? messages[messages.length - 1] : null;
        console.log("isuser", lastItem.role)
        if (lastItem)
            return Boolean(lastItem.role === "user")
        return false
    }

    //Handle share session tooltip close
    const handleTooltipClose = () => {
        setTooltip(false);
    };

    //Handle share session tooltip open
    const handleTooltipOpen = async () => {
        const link = `https://aibraham.tech/share/session/${sessionId}`
        if (!sessionId) return;
        try {
            await navigator.clipboard.writeText(link);
            setTooltip(true);
        } catch (error) {
            console.error('Error copying link to clipboard:', error);
        }
    };

    //Scroll to the bottom of the chat window
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    };

    //Handle chatwindow open or closed status
    const toggleChat = () => {
        setChatOpen(!chatOpen);
    };

    //Handle import session form visibility
    const toggleSessionInput = () => {
        setSessionInput(!sessionInput);
    };

    //Handle import session
    const sendSessionId = () => {
        setSessionId(newSessionId);
        setSessionInput(false);
    }

    //Check whether the user is waiting for answer
    useEffect(() => {
        const message = messages.slice(-1)[0];
        if (message.role === "user")
            setIsWaiting(true)
        else
            setIsWaiting(false);
    }, [messages])

    //Fetch the new messages from the agent
    useEffect(() => {

        //Check whether the session has already a chatwindow
        const isSession = localStorage.getItem('sessionId');

        //Set current session to the existing session
        if (isSession)
            setSessionId(isSession);

        //Fetch the messages for the session
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`/public/session/${sessionId}`);
                if (data.error) {
                    toast.error(data.error);
                } else {
                    if (data.messages !== messages)
                        setMessages(data.messages); //Set the new messages
                }
            } catch (error) {
                console.log(error);
            }
        };

        if (sessionId) {
            fetchData(); // Fetch data immediately on mount
            const intervalId = setInterval(fetchData, 5000); // Fetch data every 5 seconds
            return () => clearInterval(intervalId); // Cleanup function to clear interval on unmount
        }

    }, [sessionId]);

    //If there are new messages scroll to bottom of the window
    useEffect(() => {
        if (chatOpen) scrollToBottom();
    }, [messages, chatOpen]);


    //Handle the sending of the new message
    const sendMessage = async () => {

        if (newMessage.trim() === '') return; //Don't send if its empty

        try {
            //If there is no session, create a new one
            if (!sessionId) {

                //Create a session
                const { data } = await axios.post(`/public/session`, {
                    agentKey,
                    newMessage
                });

                //Handle if there is any error
                if (data.error) {
                    toast.error(data.error);
                } else {

                    setSessionId(data.sessionId); // Set the session to the new ID
                    setMessages([...messages, { content: newMessage, role: 'user', type: 'message' }]);
                    setNewMessage(''); // Empty the input

                }
            }
            //If session already existed, add new message to that
            if (sessionId) {

                // Post new messagee
                const { data } = await axios.post(`/public/session/${sessionId}`, { newMessage: newMessage });

                //Handle error
                if (data.error) {
                    toast.error(data.error);
                } else {
                    setMessages([...messages, { content: newMessage, role: 'user', type: 'message' }]);
                    setNewMessage('');
                }
            }

        } catch (error) {
            console.log(error);
        }

    };

    return chatOpen ? (
        <Container>
            <Paper
                sx={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    width: '350px',
                    height: '450px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    zIndex: '1000',
                }}
            >
                {/* HEADER */}
                <ListItem>
                    <Grid container flex={1} justifyContent={'space-between'}>
                        <Grid item xs={6}>
                            <Typography variant="h6" align="left" sx={{ padding: '0.5em' }} >
                                Aibraham
                            </Typography>
                        </Grid>

                        {/* HEADER - QUICK ACTIONS */}
                        <Grid item xs={6} container justifyContent={'flex-end'} >
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                <Tooltip
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={tooltip}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title="Copied to clipboard"
                                >
                                    <IconButton sx={{ width: '33%' }} onClick={handleTooltipOpen}>
                                        <ShareIcon />
                                    </IconButton>
                                </Tooltip>
                            </ClickAwayListener>
                            <IconButton onClick={toggleSessionInput} sx={{ width: '33%' }}>
                                <SystemUpdateAltIcon />
                            </IconButton>
                            <IconButton onClick={toggleChat} sx={{ width: '33%' }}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </ListItem>
                {
                    //{/* IMPORT SESSION FORM */}
                    sessionInput &&
                    <ListItem >
                        <Grid container flex={1} alignItems={'center'} flexDirection={'row'} pb={1}>
                            <Grid item xs={10}>
                                <TextField
                                    label="Insert session ID..."
                                    variant="outlined"
                                    fullWidth
                                    value={newSessionId}
                                    onChange={(e) => setNewSessionId(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button onClick={sendSessionId}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </ListItem>
                }

                {/* DISPLAY THE LIST OF MESSAGES */}
                <div style={{ flex: '1', overflowY: 'auto', paddingBottom: '25px' }}>
                    {messages.map((message, index) => (
                        <MessageBox key={index} message={message} />
                    ))}
                    <div ref={messagesEndRef} />
                </div>

                {/* NEW MESSAGE INPUT FORM */}
                {isWaiting && (
                    <Box sx={{ width: '100%', marginTop:4, marginBottom: 4 }}>
                        <LinearProgress />
                    </Box>
                )}
                {!isWaiting && (
                    <Grid container flex={1} alignItems={'center'} flexDirection={'row'} padding={2} maxHeight={'20%'}>
                        <Grid item xs={10}>
                            <TextField
                                disabled={isLastUser}
                                label="Type a message..."
                                variant="outlined"
                                fullWidth
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Fab
                                disabled={isLastUser}
                                color="primary"
                                aria-label="send"
                                size="small"
                                style={{ marginLeft: '10px' }}
                                onClick={sendMessage}
                            >
                                <SendIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                )}
            </Paper>
        </Container>
    ) : (
        //{/* HIDDEN CHATWINDOW */}
        <Fab
            color="primary"
            aria-label="chat"
            style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: '1000' }}
            onClick={toggleChat}
        >
            <MarkUnreadChatAltIcon />
        </Fab>
    );
};

export default ChatWindow;