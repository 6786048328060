import axios from 'axios'
import React from 'react'
import { createContext, useState, useEffect } from 'react'
import { toast } from "react-hot-toast";
import useAuth from '../hooks/useAuth';

export const AgentContext = createContext({});

/**
 * AGENT CONTEXT PROVIDER
 * 
 * Serving data for the rest of the application for an agent
 * 
 * @param {object} props Component props 
 * @param {*} props.children Component children elements
 * @returns 
 */
export default function AgentContextProvider({ children }) {

  //Current agent - loaded from the storage, or set later
  const [agent, setAgent] = useState(localStorage.getItem('selectedAgent') || '');

  //Data of the agent
  const [agentData, setAgentData] = useState({});

  //Login state
  const state = useAuth();

  //Set new agent to context
  const handleSetAgent = (newAgent) => {
    setAgent(newAgent);
    localStorage.setItem('selectedAgent', newAgent);
    return agent;
  }

  //Fetch information about the agent
  useEffect(() => {
    const fetchData = () => {
      axios.get(`/company/agent/${agent}`)
        .then(({ data }) => {
          if (data.error) {
            toast.error(data.error);
          } else {
            console.log(data)
            setAgentData(data);
          }
        })
        .catch((error) => {
          console.log(error)
          toast.error('Could not load agent data.');
        });

    }

    if (agent !== '' && state.isLogged) fetchData();
    else setAgentData({})
  }, [agent])

  return (
    <AgentContext.Provider value={{ agent, agentData, handleSetAgent , setAgentData}}>
      {children}
    </AgentContext.Provider>
  )
}
