import { Typography } from '@mui/material'
import React from 'react'

/**
 * 404 PAGE
 * @returns Not found page component
 */
export default function NotFound() {
  return (
    <div>
        <Typography variant='h1'>404 Not found</Typography>
        <Typography variant='subtitle'>This page does not exists. Please visit an other one or press return.</Typography>
    </div>
  )
}
