import { Button, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { List, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import axios from 'axios';
import { toast } from "react-hot-toast";

/**
 * MANAGERS PAGE
 * 
 * Shows all informational and functional knowledge added to the agent
 * 
 * @returns Managers page component
 */
export default function Managers() {

    //List of all managers
    const [users, setUsers] = useState([]);

    //New manager input form
    const [newManager, setNewManager] = useState({
        name: '',
        email: '',
    });

    //Open add manager form
    const [addManagerOpen, setAddManagerOpen] = useState(false);

    //Fetch managers list
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/accounts/users');
                if (response.error)
                    toast.error(response.error);
                else {
                    setUsers(response.data);
                }

            } catch (error) {
                console.error('Error fetching data.', error);
            }
        };

        fetchData();

        // eslint-disable-next-line
    }, []);


    //Handle add manager form open
    const handleOpen = () => {
        setAddManagerOpen(!addManagerOpen);
    }

    //Delete manager
    const handleDelete = async (id) => {
        try {
            const { data } = await axios.delete(`/accounts/user/${id}`)
            if (data.error) {
                toast.error(data.error);
            } else {
                setUsers([]);
                toast.success("Manager deleted.")
            }
        } catch (error) {
            console.log(error)
        }
    }

    //Add new manager
    const registerManager = async (e) => {
        e.preventDefault()
        try {
            const { data } = await axios.post('/accounts/user', { email: newManager.email, name: newManager.name })
            if (data.error) {
                toast.error(data.error);
            }
            else {
                setNewManager({ email: '', name: '' });
                setAddManagerOpen(false);
                toast.success('New manager added!');
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * LIST COMPONENT OF ALL MANAGERS
     * 
     * Displays a table list of all managers
     * 
     * @param {object} users List of users
     * @returns Component
     */
    const UserList = (users) => {

        return (
            <List>
                {users.map(user => (
                    <Paper key={user.id} elevation={3} style={{ borderRadius: 8, width: '100%', marginBottom: 8 }}>
                        <Grid container sx={{ padding: 1, paddingLeft: 3, paddingRight: 3 }} alignItems={'center'}>
                            <Grid item xs={5}>
                                <Typography variant="body1" >{user.name}</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="body1" >{user.email}</Typography>
                            </Grid>
                            <Grid item xs={2} container justifyContent={'flex-end'}>
                                <IconButton edge="end" aria-label="delete" onClick={() => { handleDelete(user.id) }} >
                                    <Delete />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Paper>
                ))}
            </List>
        );
    };

    return (
        <div>
            {/* HEADER */}
            <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                <Grid item xs={6}>
                    <Typography variant='h2' align="left">Managers</Typography>
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end" alignItems="bottom">
                    <Button variant="contained" size="large" onClick={handleOpen}>Add New</Button>
                </Grid>
            </Grid>

            {/* LABEL */}
            <Grid container sx={{ padding: 1, paddingLeft: 3, paddingRight: 3 }} alignItems={'center'}>
                <Grid item xs={5}>
                    <Typography variant="body2" >Name</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="body2" >Email</Typography>
                </Grid>
                <Grid item xs={2} container justifyContent={'flex-end'}>
                    <Typography variant="body2" >Actions</Typography>
                </Grid>
            </Grid>

            {
                //NEW MANAGER INPUT FORM
                addManagerOpen && (
                    <form onSubmit={registerManager}>
                        <Grid container spacing={2} >
                            <Grid item xs={5}>
                                <TextField fullWidth label="Name" variant="outlined" value={newManager.name} onChange={(e) => setNewManager({ ...newManager, name: e.target.value })} required />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField fullWidth label="Email" variant="outlined" type="email" value={newManager.email} onChange={(e) => setNewManager({ ...newManager, email: e.target.value })} required />
                            </Grid>
                            <Grid item xs={2} container sx={{alignItems: "stretch" }}>
                                <Button fullWidth type="submit" variant="contained" size="large" fullwidth="true">Add manager</Button>
                            </Grid>
                        </Grid>
                    </form>
                )
            }

            {/* MANAGERS LIST */}
            {UserList(users)}
        </div>
    )
}
