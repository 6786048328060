import { Grid, Paper, Typography } from '@mui/material'
import React from 'react'

/**
 * QUICKSTART PAGE 
 * 
 * Providing insight for new users
 * 
 * @returns Quickstart page component
 */
export default function QuickStart() {
    return (
        <>
            <Typography variant='h2' mb={2}>Quick Start Guide</Typography>
            <Grid container >
                <Grid item xs={12}>
                    <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>
                        <Grid container flexDirection={'row'}>
                            <Grid item xs={1}>
                                <Typography variant='h3'>1.</Typography>
                            </Grid>
                            <Grid item xs={11} container flexDirection={'column'}>
                                <Typography variant='h5'>Activate your account</Typography>
                                <Typography variant='body2'>First, you must activate your account. You can do this by going to the billings tab, where you click the Activate button.</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>
                        <Grid container flexDirection={'row'}>
                            <Grid item xs={1}>
                                <Typography variant='h3'>2.</Typography>
                            </Grid>
                            <Grid item xs={11} container flexDirection={'column'}>
                                <Typography variant='h5'>Create an agent</Typography>
                                <Typography variant='body2'>Agents will assist your customers. To create an agent choose an agent and click on Add new.</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>
                        <Grid container flexDirection={'row'}>
                            <Grid item xs={1}>
                                <Typography variant='h3'>3.</Typography>
                            </Grid>
                            <Grid item xs={11} container flexDirection={'column'}>
                                <Typography variant='h5'>Teach the agent</Typography>
                                <Typography variant='body2'>To define your products and services you need to add knowledge to the agent. To add a new knowledge visit the Knowledge tab.</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>
                        <Grid container flexDirection={'row'}>
                            <Grid item xs={1}>
                                <Typography variant='h3'>4.</Typography>
                            </Grid>
                            <Grid item xs={11} container flexDirection={'column'}>
                                <Typography variant='h5'>Integrate the agent</Typography>
                                <Typography variant='body2'>To integrate an agent you will need its secret key, which can be found under the Settings tab. Use the secret key in an implementation to integrate. Please visit the documentation for further information.</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>
                        <Grid container flexDirection={'row'}>
                            <Grid item xs={1}>
                                <Typography variant='h3'>5.</Typography>
                            </Grid>
                            <Grid item xs={11} container flexDirection={'column'}>
                                <Typography variant='h5'>Manage the sessions</Typography>
                                <Typography variant='body2'>Keep your sessions and tickets clean and up-to-date by managing them under the Sessions and Tickets tab.</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}
