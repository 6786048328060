import React from 'react'
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import documentjson from '../helper/openapi.json'

/**
 * API DOCUMENTATION PAGE
 * @returns Swagger API documentation component
 */
export default function ApiDocumentation() {
  return (
    <div style={{backgroundColor: 'white'}}>
        <SwaggerUI spec={documentjson} />
    </div>
    
  )
}
