import { AppBar, Container, CssBaseline, Grid, Toolbar, Typography } from '@mui/material';
import Menubar from '../components/Menubar';
import { Routes, Route, Navigate } from 'react-router-dom'
import Home from '../pages/Home';
import Login from '../pages/Login';
import Register from '../pages/Register';
import { Toaster } from 'react-hot-toast';
import RequireAuthorization from '../hooks/RequireAuthorization';
import { ROLES } from '../config/roles'
import AuthOutlet from '@auth-kit/react-router/AuthOutlet';
import VerticalMenu from '../components/VerticalMenu';
import Managers from '../pages/Managers';
import AgentContextProvider from '../context/AgentContext';
import useAuth from '../hooks/useAuth';
import Options from '../pages/Options';
import Knowledge from '../pages/Knowledge';
import KnowledgeForm from '../components/KnowledgeForm';
import AddAgent from '../pages/AddAgent';
import Sessions from '../pages/Sessions';
import SessionsForm from '../components/SessionsForm';
import ChatWindow from '../components/ChatWindow';
import Billing from '../pages/Billing';
import Tickets from '../pages/Tickets';
import Finetune from '../pages/Finetune';
import NotFound from '../pages/NotFound';
import Profile from '../pages/Profile';
import QuickStart from '../pages/QuickStart';

/**
 * ADMIN APPLICATION STRUCTURE AND ROUTING
 * @returns Main application construct
 */
function AdminApp() {

    //Login State
    const state = useAuth();

    return (
        <div>
            <CssBaseline />

            {/* Customer support chatbot integratod in the application */}
            <ChatWindow agentKey={"IchJBZVvmgCzjKrkEUM9jw=="}></ChatWindow>

            <Container maxWidth="1350px" fixed>

                <AgentContextProvider>

                    <Grid container style={{ height: '100vh' }}>

                        {/* LEFT - Vertical Bar */}
                        <Grid item xs={12} md={2}>
                            <AppBar position="static" sx={{ backgroundColor: '#191919' }} elevation={0}>
                                <Toolbar>
                                    <a href="/login" style={{textDecoration: 'none', color: 'inherit', cursor: 'pointer'}}>
                                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                        AIBRAHAM
                                    </Typography>
                                    </a>
                                </Toolbar>
                            </AppBar>
                            {state.isLogged && <VerticalMenu />}
                        </Grid>

                        {/* RIGHT - MAIN CONTENT */}
                        <Grid item xs={12} md={10} container flex={1} flexDirection={'row'} alignContent={'flex-start'}>
                            
                            {/* HEADER MENU */}
                            <Grid item xs={12} pl={3}>
                                <Menubar></Menubar>
                            </Grid>

                            <Grid item xs={12} p={6} pr={0} minHeight="87%" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <main style={{ height: '100%' }}>
                                    <Routes>

                                        {/*PUBLIC ROUTES*/}
                                        <Route path='/login' element={state.isLogged ? <Navigate to="/home" replace /> : <Login />} />{/*If logged in redirect to home if not login*/}
                                        <Route path='/register' element={state.isLogged ? <Navigate to="/home" replace /> : <Register />} />{/*If logged in redirect to home if not register*/}

                                        <Route path='/sajt/share/session/:id' element={<SessionsForm isPreview={false} isGuest={true} />} />{/*Public view of the session*/}

                                        {/*PASSWORD PROTECTED ROUTES*/}
                                        <Route element={<AuthOutlet fallbackPath='/login' />}>

                                            <Route path='/home' element={ <Home/>} />{/*Rerirects to quickstart if no agent is selected */}

                                            <Route path='/start' element={<QuickStart />} />{/*Initial setup tutorial*/}

                                            <Route path='/profile' element={<Profile />} />{/*Logged in manager profile settings */}

                                            <Route path='/knowledge' element={<Knowledge />} />{/*Agents knowledge overview*/}
                                            <Route path='/knowledge/new' element={<KnowledgeForm isNew={true} />} />{/*Add new knowledge*/}
                                            <Route path='/knowledge/:id' element={<KnowledgeForm isNew={false} />} />{/*Manage knowledge*/}


                                            <Route path='/finetune' element={<Finetune />} />{/*Agents fine tune settings*/}

                                            <Route path='/sessions' element={<Sessions />} />{/*Agents sessions overview*/}
                                            <Route path='/sessions/:id' element={<SessionsForm />} />{/*Manage session*/}
                                            
                                            <Route path='/tickets' element={<Tickets />} />{/*Agents tickets overview*/}
                                            <Route path='/ticket/:id' element={<SessionsForm isNew={false} isPreview={false} />} />{/*Manage ticket*/}

                                            <Route path='/agent/add' element={<AddAgent />} />{/*Add new agent*/}
                                            <Route path='/settings' element={<Options />} />{/*Agents settings*/}

                                            

                                            {/*ROLE PROTECTED ROUTES*/}

                                            <Route element={<RequireAuthorization allowedRoles={[ROLES.Supervisor]} />}>
                                                <Route path='/managers' element={<Managers />} />{/*Company managers*/}
                                            </Route>

                                            <Route element={<RequireAuthorization allowedRoles={[ROLES.Supervisor]} />}>
                                                <Route path='/billing' element={<Billing />} />{/*Companys billings*/}
                                            </Route>

                                        </Route>

                                        <Route path='/*' element={<NotFound />} />{/*Falloff 404*/}

                                    </Routes>
                                </main>

                            </Grid>

                            {/* Need help? footer */}
                            <Grid item xs={12}>
                                <Typography align='right' variant='body2' pb={2} fontWeight={200}><a style={{color: 'white', textDecoration:'none'}} href='/documentation'>Need help? Click here to read more.</a></Typography>
                            </Grid>

                        </Grid>
                    </Grid>

                </AgentContextProvider>
            </Container>


            {/*Notifications*/}
            <Toaster position='bottom-left' toastOptions={{ duration: 2000 }} />

            {/*Gray background element*/}
            <Grid container sx={{ width: { xs: 0, md: 'calc(50% - 265px)', lg: 'calc(50% - 380px)', xl: 'calc(50% - 475px)' }, position: 'fixed', top: 0, left: 0, height: '100vh', backgroundColor: '#191919', zIndex: -1 }}>
                <div></div>
            </Grid>
        </div>
    );
}

export default AdminApp;