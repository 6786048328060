import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { Button, ButtonBase, Grid, Paper, TextField, Typography } from "@mui/material";

export default function Login() {

    const navigate = useNavigate();
    const [isReset, setIsReset] = useState(false)

    const handleSetReset = () => {
        setIsReset(!isReset)
    }

    /**
     * RESET PASSWORD
     * 
     * Handles a two step password reset where the user first requests a reset token via email then proceeds to reset with the token
     * @returns two step password reset component
     */
    const ResetPassword = () => {
        const [resetSecondStep, setResetSecondStep] = useState(false)

        /**
         * Handles the collection of the email address when resetting a password
         * After the email collection the system sends out a reset token to the mail
         * @returns component representing the first step of resetting the password
         */
        const FirstStep = () => {

            const [email, setEmail] = useState('')

            const handleResetRequest = async (e) => {
                e.preventDefault();
                try {
                    const { data } = await axios.post(`/login/reset`, { email: email });
                    //const data = {success:true}
                    if (data.error) {
                        toast.error(data.error);
                    } else {
                        toast.success("Email sent.")
                        setResetSecondStep(true);
                    }
                } catch (error) {
                    console.error(error);
                }
            }

            return (
                <form onSubmit={handleResetRequest}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField fullWidth label="Email" variant="outlined" type="email" value={email} onChange={(e) => { setEmail(e.target.value) }} required />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">Insert your email and we will send you a confirmation code.</Typography>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={8}>
                                <ButtonBase onClick={handleSetReset}>
                                    <Typography variant="body2">Back to the login</Typography>
                                </ButtonBase>

                            </Grid>
                            <Grid item xs={4} container justifyContent={'flex-end'}>
                                <Button fullWidth variant="contained" size="large" type="submit">SEND</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            )
        }

        /**
         * Handles the password reset with the received reset token
         * @returns component representing the second step of resetting the password
         */
        const SecondStep = () => {

            const [resetData, setResetData] = useState({
                confirmCode: '',
                password: ''
            })

            const handleChangeResetData = (event) => {
                const { name, value } = event.target;
                setResetData({ ...resetData, [name]: value });
            };

            const handleResetSubmit = async (e) => {
                e.preventDefault();
                try {
                    const { data } = await axios.post(`/login/reset/submit`, {resetCode: resetData.confirmCode, newPassword: resetData.password});
                    if (data.error) {
                        toast.error(data.error);
                    } else {
                        console.log(data)
                        setResetData({
                            confirmCode: '',
                            password: ''
                        });
                        setResetSecondStep(false);
                        setIsReset(false);
                        toast.success("Password changed.")
                    }
                } catch (error) {
                    console.error(error);
                }
            }

            return (<form onSubmit={handleResetSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField fullWidth label="Confirmation code" name="confirmCode" variant="outlined" value={resetData.confirmCode} onChange={handleChangeResetData} required />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth label="New password" name="password" variant="outlined" value={resetData.password} onChange={handleChangeResetData} required />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2">An email was sent to you with the confirmation code.</Typography>
                    </Grid>
                    <Grid item xs={12} container>
                        <Grid item xs={8}>
                            <ButtonBase onClick={handleSetReset}>
                                <Typography variant="body2">Back to the login</Typography>
                            </ButtonBase>

                        </Grid>
                        <Grid item xs={4} container justifyContent={'flex-end'}>
                            <Button fullWidth variant="contained" size="large" type="submit">RESET</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>)
        }

        return (
            <>
                {!resetSecondStep && (<FirstStep />)}
                {resetSecondStep && (<SecondStep />)}
            </>
        )
    }

    /**
     * LOGIN FORM
     * 
     * Requests email and password
     * Signs in with react-auth-kit using Bearer
     * @returns login component
     */
    const LoginForm = () => {

        const signIn = useSignIn();
        const [data, setData] = useState({
            email: '',
            password: ''
        });

        const loginUser = async (e) => {
            e.preventDefault()
            const { email, password } = data;
            try {
                const { data } = await axios.post('/login', { email, password })
                if (data.error) {
                    toast.error(data.error);
                }
                else {
                    signIn({
                        auth: {
                            token: data.token,
                            tokenType: "Bearer"
                        },
                        userState: {
                            email: data.email,
                            id: data.id,
                            name: data.name,
                            roles: data.roles,
                            company: data.company
                        },
                    });
                    setData({ email: '', password: '' });
                    navigate('/home');
                    window.location.reload()
                    toast.success('Welcome back!');
                }
            } catch (error) {
                console.log(error);
            }
        }

        const handleRegister = () => {
            navigate('/register');
        }

        return (<form onSubmit={loginUser}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField fullWidth label="Email" variant="outlined" type="email" value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} required />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth label="Password" variant="outlined" type="password" value={data.password} onChange={(e) => setData({ ...data, password: e.target.value })} required />
                </Grid>
                <Grid item xs={12} container spacing={2}>
                    <Grid item xs={6}>
                        <ButtonBase onClick={handleSetReset}>
                            <Typography variant="body2">Forgot your password?</Typography>
                        </ButtonBase>

                    </Grid>
                    <Grid item xs={3} container justifyContent={'flex-end'}>
                        <Button fullWidth variant="outlined" size="large" fullwidth="true" onClick={handleRegister}>Register</Button>
                    </Grid>
                    <Grid item xs={3} container justifyContent={'flex-end'}>
                        <Button fullWidth type="submit" variant="contained" size="large" fullwidth="true">Login</Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>)
    }

    return (
        <Grid container alignContent={'center'} height={'100%'}>
            <Grid item xs={6}>
                <Typography variant="h2" mb={2}>{isReset ? "Reset Password" : "Log in"}</Typography>
                <Paper elevation={3} sx={{ padding: 4 }}  >
                    {isReset && <ResetPassword />}
                    {!isReset && <LoginForm />}
                </Paper>
            </Grid>
            <Grid item xs={6}>
            </Grid>
        </Grid>
    )
}