import { Button, Grid, TextField, FormControlLabel, Checkbox, Typography, Paper } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from '@mui/icons-material/';

/**
 * REGISTRATION PAGE
 * @returns component which fulfills the registration page
 */
export default function Register() {
    const navigate = useNavigate();

    //Form data
    const [formData, setFormData] = useState({
        businessName: '',
        businessId: '',
        vatId: '',
        name: '',
        email: '',
        password: ''
    })

    //Password visibility status
    const [showPassword, setShowPassword] = useState(false);

    //Business form status visibility
    const [isBusinessCustomer, setIsBusinessCustomer] = useState(false);

    //Handlee form change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    //Register
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const { data } = await axios.post('/register', {
                ...formData,
                isBusinessCustomer
            })
            if (data.error) {
                toast.error(data.error);
                if (data.company)
                    setFormData({
                        ...formData,
                        company: data.company
                    })
                console.log(this.data)
            } else {
                setFormData({
                    businessName: '',
                    businessId: '',
                    vatId: '',
                    name: '',
                    email: '',
                    password: ''
                });
                navigate('/login');
                toast.success('Registration Successful. Welcome!');
            }
        } catch (error) {
            console.log(error);
        }
    };

    //Handle password visibility status
    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    //Open or close business form
    const handleBusinessCustomerChange = () => {
        setIsBusinessCustomer(!isBusinessCustomer);

        if (!isBusinessCustomer) {
            setFormData({
                ...formData,
                businessId: '',
                vatId: ''
            });
        }
    };


    /**
     * Bottom component for providing extra information and the submission
     * @returns component
     */
    const RegisterInfo = () => (
        <Grid container spacing={2} flexDirection={'row'}>
            <Grid item xs={9} container alignItems={'center'}>
                <Typography variant="body1">
                    By registering you accept our Terms & Conditions and our Privacy Policy.
                </Typography>
            </Grid>
            <Grid item xs={3} container alignItems={'flex-end'}>
                <Button fullWidth type="submit" variant="contained" color="primary">Register</Button>
            </Grid>
        </Grid>
    )

    return (
        <div>
            <Grid container alignItems="center" justifyContent="space-between" spacing={2} mb={2}>
                <Grid item xs={6}>
                    <Typography variant='h2' align="left">Registration</Typography>
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end" alignItems="bottom">

                </Grid>
            </Grid>

            <form onSubmit={handleSubmit}>
                <Grid container spacing={2} >

                    {/*COMPANY INFORMATION*/}

                    <Grid item xs={6} container alignItems={'stretch'}>
                        <Paper elevation={3} sx={{ padding: 4, width: '100%' }} >
                            <Grid container>
                                <Grid item xs={12} pb={2}>
                                    <Typography variant="h4">Organisation</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField fullWidth label="Organisation name" variant="outlined" name="businessName" value={formData.businessName} onChange={handleChange} required />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox checked={isBusinessCustomer} onChange={handleBusinessCustomerChange} />}
                                            label="I am a business customer"
                                        />
                                    </Grid>
                                    {isBusinessCustomer && (
                                        <>
                                            <Grid item xs={12}>
                                                <TextField fullWidth label="Business ID" variant="outlined" name="businessId" value={formData.businessId} onChange={handleChange} required />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField fullWidth label="VAT ID" variant="outlined" name="vatId" value={formData.vatId} onChange={handleChange} />
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {/*SUPERVISOR INFORMATION*/}

                    <Grid item xs={6} container alignItems={'stretch'}>
                        <Paper elevation={3} sx={{ padding: 4, width: '100%' }} >
                            <Grid container  pb={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h4">Supervisor</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        This user will administrate the company account.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField fullWidth label="Name" variant="outlined" name="name" value={formData.name} onChange={handleChange} required />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField fullWidth label="Email" variant="outlined" type="email" name="email" value={formData.email} onChange={handleChange} required />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Password"
                                            variant="outlined"
                                            type={showPassword ? 'text' : 'password'}
                                            name="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            required
                                            InputProps={{
                                                endAdornment: (
                                                    <Button onClick={handleTogglePasswordVisibility}>
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </Button>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>


                    {/* REGISTRATION INFO AND SUBMIT */}
                    <Grid item xs={12}>
                        <Paper elevation={3} sx={{ padding: 4, width: '100%' }} >
                            <RegisterInfo />
                        </Paper>
                    </Grid>

                </Grid>
            </form>
        </div>
    )
} 