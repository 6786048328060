import React, { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';

/**
 * UNIVERSAL EXPANDABLE LIST
 * 
 * Takes a list with fix elements and creates an expandable list of with the defined component with the items from the list
 * 
 * @param {object} props Component props 
 * @param {Array} props.list List of items
 * @param {any} props.componentType Component type to create
 * @returns Universal expandable list component
 */
function ExpandableList({ list, componentType }) {

  //Expansion status
  const [showAll, setShowAll] = useState(false);

  //Show more
  const handleShowMore = () => {
    setShowAll(true);
  };

  //Show less
  const handleShowLess = () => {
    setShowAll(false);
  };

  const baseLength = 5;

  return (
      <Grid container>
        {list.slice(0, showAll ? list.length : baseLength).map((item, index) => (
          <Grid item xs={12} key={index}>
            {React.createElement(componentType, { item })}
          </Grid>
        ))}
        {list.length > 5 && (
          <Grid item xs={12} mt={1} container>
            <Grid item xs={6}>
              {!showAll ? (
                <Button variant="outlined" onClick={handleShowMore}>
                  Show More
                </Button>
              ) : (
                <Button variant="outlined" onClick={handleShowLess}>
                  Show Less
                </Button>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography align='right' variant='body2'>Showing {showAll ? list.length : baseLength} of {list.length}</Typography>
            </Grid>

          </Grid>
        )}
      </Grid>
  );
}

export default ExpandableList;