import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "./useAuth";

/**
 * AUTHORIZES THE ROUTE BASED ON THE ROLES FROM THE COOKIES
 * Use:
 * 
 *      <Route element={<RequireAuthorization allowedRoles={[ROLES.Admin]}/>}>
 *           protected route
 *      </Route>
 * 
 * @param {object} params Parameters
 * @param {Array} allowedRoles Allowed roles
 * @returns Either renders the component or refers to the login page
 */
const RequireAuthorization = ({allowedRoles}) => {
    const location = useLocation();
    const {roles} = useAuth(); // GETS THE DATA FROM THIS MODULE

    const content = (
        roles.some(role => allowedRoles.includes(role)) 
            ? <Outlet/> //RETURN A RENDER OF THE CHILD ELEMENTS
            : <Navigate to='/login' state={{from: location}} replace /> //REDIRECTS TO LOGIN
    )

    return content
}

export default RequireAuthorization