import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, AppBar, Button, Container, CssBaseline, Grid, List, ListItem, ListItemText, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { Toaster } from 'react-hot-toast'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/**
 * DOCUMENTATION PAGE
 * 
 * User documentation for each part of the application.
 * 
 * @returns Documentation page component
 */
export default function Documentation() {
    return (
        <div>
            <CssBaseline />
            <Container maxWidth="1350px" fixed>

                <Grid container style={{ height: '100vh' }}>

                    {/* LEFT COLUMN */}
                    <Grid item xs={12} md={2}>
                        <AppBar position="static" sx={{ backgroundColor: '#191919' }} elevation={0}>
                            <Toolbar>
                                <a href="/login" style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
                                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                        AIBRAHAM
                                    </Typography>
                                </a>
                            </Toolbar>
                        </AppBar>
                    </Grid>

                    {/* RIGHT COLUMN - CONTENT*/}
                    <Grid item xs={12} md={10} container flexDirection={'row'} alignContent={'flex-start'}>

                        <Grid item xs={12} p={{ xs: 4, md: 6 }} pr={{ xs: 4, md: 0 }} minHeight="87%" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <main style={{ height: '100%' }}>
                                <Typography variant='h2' mb={4} fontSize={{ xs: '2em', md: '3em' }}>Documentation</Typography>
                                <Typography variant='body2' textAlign={'justify'} mb={4} fontWeight={200}>
                                    Are you prepared to take your business to the next level? At our company, we specialize in delivering top-notch sales and customer service solutions through the use of advanced AI agents renowned for their precision. Our agents operate swiftly, accurately, and consistently, available round-the-clock to meet your needs. By entrusting the mundane, repetitive tasks to our AI agents, you can optimize your workforce's potential, enabling them to focus on more impactful and strategic endeavors. Experience the efficiency and effectiveness of our AI-powered solutions and witness the transformation of your business operations.
                                </Typography>

                                {/* QUICKSTART */}
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography variant='h4'>QuickStart</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant='body1' gutterBottom textAlign={'justify'}>
                                            Begin by visiting the application page and selecting the "Register" option. You'll then be prompted to provide company details for billing purposes and personal information to establish a connected company supervisor account.
                                        </Typography>
                                        <Typography variant='body1' gutterBottom textAlign={'justify'}>
                                            Upon completing registration, proceed to log in. Before accessing our services, it's essential to activate your account. Follow these steps to do so: Upon logging in, you'll land on the homepage. Navigate to the vertical menu on the left-hand side and select "Billing." Here, you can review the current status of your account. Click on "Activate Account" and complete the payment process. Each activation extends your account's validity by 30 days. Simply repeat the activation process to prolong your account's activation period—30 additional days with each cycle. Upon successful payment, you'll find the corresponding invoice under the "Billings" tab.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                {/* AGENTS */}
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                    >
                                        <Typography variant='h4'>Agents</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1" gutterBottom>
                                            Agents serve as the cornerstone of our project, functioning as both salespersons and customer assistants. Meticulously crafted, they are tailored to meet all your and your customers' requirements.
                                        </Typography>

                                        <Typography variant="body1" gutterBottom>
                                            To create a new agent, navigate to the dropdown menu on the left-hand side and select "Add New." You'll then be prompted to input the following details:
                                        </Typography>

                                        <List>
                                            <ListItem>
                                                <ListItemText primary={<Typography variant="body1"><strong>Name:</strong> A custom identifier for internal use.</Typography>} />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={<Typography variant="body1"><strong>Type:</strong> Specify whether the agent is intended for sales or customer support purposes.</Typography>} />
                                            </ListItem>
                                        </List>

                                        <Typography variant="body1" gutterBottom>
                                            Agents can be configured in two distinct ways:
                                        </Typography>

                                        <List>
                                            <ListItem>
                                                <ListItemText primary={<Typography variant="body1"><strong>Sales:</strong> Configured to emulate a salesperson, equipped with the necessary information to persuade users to consider or purchase your product.</Typography>} />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={<Typography variant="body1"><strong>Customer Support:</strong> Configured as an assistant for external interactions, providing timely and relevant information to clients. This includes details about general terms and conditions, return policies, service offerings, opening hours, product specifications, and more. Additionally, they can be utilized internally to support employees in various tasks such as technical support, adherence to company standards, drafting summaries, or composing emails.</Typography>} />
                                            </ListItem>
                                        </List>

                                        <Typography variant="body1" gutterBottom>
                                            To tailor the agent to your specific requirements, you can make adjustments at any time by accessing the Settings page from the vertical menu.
                                        </Typography>

                                        <Typography variant="body1" gutterBottom>
                                            Once the agent is created, the next step involves educating it about your company and product. Please proceed to the next section for guidance on Knowledge.
                                        </Typography>
                                    </AccordionDetails>

                                </Accordion>

                                {/* KNOWLEDGE */}
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography variant='h4'>Knowledge</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1" gutterBottom>
                                            Agents are akin to blank documents, possessing the capacity to provide support but lacking initial information. To empower them to assist you effectively, you must furnish them with information. These informational units are referred to as Knowledge.
                                        </Typography>

                                        <Typography variant="body1" gutterBottom>
                                            Knowledge represents structured pieces of information on specific topics. To add new knowledge, navigate to the Knowledge page listed in the vertical menu. Here, you can view all existing knowledge and add new entries by clicking "Add New" in the top right corner.
                                        </Typography>

                                        <Typography variant="body1" gutterBottom>
                                            Knowledge is categorized into two types:
                                        </Typography>

                                        <List>
                                            <ListItem>
                                                <ListItemText primary={<Typography variant="body1"><strong>Informational:</strong> Knowledge based purely on textual information. You'll be prompted to provide a title for easier identification and a description to elaborate on the topic/product/service.</Typography>} />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={<Typography variant="body1"><strong>Functional:</strong> Intended for developers. Please refer to the "For Developers" section before use. Functional knowledge comprises executable functions for the agent. You'll need to define the function name, provide a description of its usage, and specify input parameters.</Typography>} />
                                            </ListItem>
                                        </List>

                                        <Typography variant="body1" gutterBottom>
                                            Agents' knowledge is only updated when the new knowledge is marked as Active. Knowledge marked as Offline is not utilized by the agent. You have the flexibility to modify knowledge at any time.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                {/* SESSIONS */}
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography variant='h4'>Sessions</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1" gutterBottom>
                                            Each time a user interacts with your agent, a session is initiated. You can monitor your active sessions by accessing the Sessions page from the Vertical menu.
                                        </Typography>

                                        <Typography variant="body1" gutterBottom>
                                            Sessions are not directly managed since they are handled by the agent. They serve exclusively for user inquiries.
                                        </Typography>

                                        <Typography variant="body1" gutterBottom>
                                            You can enhance session organization by assigning tags. Simply click the "+ Tag" button below the session title, and to save the assigned tags, click "Save" in the top right corner. Tags facilitate better overview and identification for your managers.
                                        </Typography>

                                        <Typography variant="body1" gutterBottom>
                                            To manually manage a session, it needs to be migrated as a ticket. Refer to the ticket section for further instructions.
                                        </Typography>

                                        <Typography variant="body1" gutterBottom>
                                            To initiate a new session, click "Add New" in the top right corner. This action automatically generates a public link for the session.
                                        </Typography>

                                        <Typography variant="body1" gutterBottom>
                                            Caution! Sessions are distinguished by public and internal links:
                                        </Typography>

                                        <List>
                                            <ListItem>
                                                <ListItemText primary={<Typography variant="body1"><strong>Public link:</strong> Intended for guest interaction. The opener can interact with the session from the user's side.</Typography>} />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={<Typography variant="body1"><strong>Internal link:</strong> The session's internal link is accessible only to logged-in company managers. Guests visiting this page won't be able to interact with or view the session.</Typography>} />
                                            </ListItem>
                                        </List>
                                    </AccordionDetails>
                                </Accordion>

                                {/* TICKETS */}
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography variant='h4'>Tickets</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1" gutterBottom>
                                            Sessions can be transitioned into tickets. Tickets represent sessions that are disconnected from the Agent, serving as a means for your employees to manage sessions - introducing the human factor.
                                        </Typography>

                                        <Typography variant="body1" gutterBottom>
                                            Your tickets can be accessed under the "Tickets" tab in the vertical menu.
                                        </Typography>

                                        <Typography variant="body1" gutterBottom>
                                            To manage a ticket, click on the desired ticket and then the "Open" button. Here, you can add messages to the ticket for both parties - the user and the manager. Messages are automatically saved upon addition.
                                        </Typography>

                                        <Typography variant="body1" gutterBottom>
                                            If a ticket is associated with an email, the user will receive email notifications regarding new messages (Email acceptance must be enabled in the agent configuration).
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                {/* MANAGERS */}
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography variant='h4'>Managers</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1" gutterBottom>
                                            Aibraham distinguishes between two types of users:
                                        </Typography>

                                        <List>
                                            <ListItem>
                                                <ListItemText primary={<Typography variant="body1"><strong>Manager:</strong> Managers are your employees, empowered to manage sessions, tickets, and agents.</Typography>} />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={<Typography variant="body1"><strong>Supervisor:</strong> Assigned to a company and cannot be deleted. Supervisors possess the same functionalities as managers but also oversee company settings and handle bills and manager accounts.</Typography>} />
                                            </ListItem>
                                        </List>

                                        <Typography variant="body1" gutterBottom>
                                            To manage currently available users, navigate to the "Managers" tab in the vertical menu.
                                        </Typography>

                                        <Typography variant="body1" gutterBottom>
                                            To add a new manager, click "Add New" in the top right corner. You'll be prompted to provide the new manager's name and email address. The new manager will receive their password via email.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                {/* COST & BILLING */}
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography variant='h4'>Cost & Billing</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1" gutterBottom>
                                            At Aibraham, we prioritize providing the highest value and best user experience, which is why our agents are based on OpenAI's innovative AI models. However, as an external provider, the billing process can be somewhat complex. Therefore, we've implemented a two-step approach.
                                        </Typography>

                                        <Typography variant="body1" gutterBottom>
                                            To ensure the continuous freshness and innovation of your project, we charge a monthly subscription fee for our services, which renews every 30 days after activation. The activation fee is €150 for a 30-day period.
                                        </Typography>

                                        <Typography variant="body1" gutterBottom>
                                            Aibraham caters to small, medium, and large businesses without limiting monthly usage, as it varies across different business niches. Hence, we've decided to propagate usage fees to you. Each month, you'll receive a bill based on the usage for the previous billing period. Usage is measured in tokens, which comprise the sum of your input and output tokens at the external provider. The cost of each token is €0.005.
                                        </Typography>

                                        <Typography variant="body1" gutterBottom>
                                            At Aibraham, your successful career relies on two key elements:
                                        </Typography>

                                        <List>
                                            <ListItem>
                                                <ListItemText primary={<Typography variant="body1"><strong>Active account:</strong> Your account must remain active to keep your agents online. Once the account is offline, your agents will cease to respond.</Typography>} />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={<Typography variant="body1"><strong>Paid bills:</strong> Timely payment of bills is essential. We understand that circumstances may arise, so we provide a 14-day reserve period. However, if bills remain unpaid after this period, your account will be disabled, even if you have an active membership.</Typography>} />
                                            </ListItem>
                                        </List>
                                    </AccordionDetails>
                                </Accordion>

                                {/* CHAT INTEGRATION */}
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography variant='h4'>Chat integration</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1" gutterBottom>
                                            Your clients need a convenient way to reach your agents. Currently, we offer two methods to achieve this. As showcased in the Sessions section, you can create and share public session links, although this is only suitable for specific use cases.
                                        </Typography>

                                        <Typography variant="body1" gutterBottom>
                                            The second and most common method is to integrate the Aibraham chat window into your webpage.
                                        </Typography>

                                        <Typography variant="body1" gutterBottom>
                                            To do this, please install the pre-prepared package provided for you:
                                        </Typography>

                                        <List>
                                            <ListItem>
                                                <ListItemText primary={<Typography variant="body1"><strong>React:</strong> Click <a style={{ color: 'white' }} href="react_package_link_here" target="_blank" rel="noopener noreferrer">here</a> to for the installation package.</Typography>} />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={<Typography variant="body1"><strong>Wordpress:</strong> Coming soon.</Typography>} />
                                            </ListItem>
                                        </List>

                                        <Typography variant="body1" gutterBottom>
                                            After installation, select the agent in the Aibraham App that you would like to include. Visit the agent settings page, where you'll find a secret key provided by us. Copy this key and enter it into the chat integration. The chat window will communicate with us through your secret key.
                                        </Typography>

                                        <Typography variant="body1" gutterBottom>
                                            The provided package includes all the basic features you would need. If you wish to define your own functions, please visit the For Developers section.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                {/* FOR DEVELOPERS */}
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography variant='h4'>For developers</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body1" gutterBottom>
                                            Although we encourage you to use our pre-prepared package, feel free to modify, customize, or build your own solution.
                                        </Typography>

                                        <Typography variant="body1" gutterBottom>
                                            All the necessary information and must-haves are highlighted in the package.
                                        </Typography>

                                        <Typography variant="body1" gutterBottom>
                                            Aibraham agents are capable of launching custom functions, which are defined by you between the knowledge sections. When the agent decides to launch one of these custom functions, the request is returned to the front-end - the chat window. Here, you must accept and handle the request, then return the output to the given API endpoint. This API endpoint will handle sourcing the output to the agent. Example function handling is showcased in the package.
                                        </Typography>

                                        <Typography variant="body1" gutterBottom>
                                            If you have any questions, feel free to contact us.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                {/* BACKEND API */}
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography variant='h4'>Backend API</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant='body1' gutterBottom textAlign={'justify'}>
                                            You can find our API documentation on the link below.
                                        </Typography>
                                    </AccordionDetails>
                                    <AccordionActions>
                                        <Button href='/documentation/api'>See Documentation</Button>
                                    </AccordionActions>
                                </Accordion>
                            </main>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>


            {/* NOTIFICATIONS */}
            <Toaster position='bottom-left' toastOptions={{ duration: 2000 }} />

            {/* LIGHT GRAY BACKGROUND ELEMENT */}
            <Grid container sx={{ width: { xs: 0, md: 'calc(50% - 265px)', lg: 'calc(50% - 380px)', xl: 'calc(50% - 475px)' }, position: 'fixed', top: 0, left: 0, height: '100vh', backgroundColor: '#191919', zIndex: -1 }}>
                <div></div>
            </Grid>
        </div>
    )

}
