import React, { useContext, useEffect, useState } from 'react';
import { Typography, Button, Grid, ClickAwayListener, Tooltip } from '@mui/material';
import { AgentContext } from '../context/AgentContext';
import toast from 'react-hot-toast';
import axios from 'axios';
import { prettyDate, timeAgo } from '../helper/Time';
import SessionItem from '../components/SessionItem';
import ExpandableList from '../components/ExpandableList';

/**
 * SESSIONS OVERVIEW PAGE
 * 
 * @returns Sessions page
 */
export default function Sessions() {

    //Kept sessions
    const [sessions, setSessions] = useState([]);
    const agentContext = useContext(AgentContext);
    const { agentData } = agentContext;

    //Add new tooltip status
    const [tooltip, setTooltip] = useState(false);

    //Handle add new tooltip close
    const handleTooltipClose = () => {
        setTooltip(false);
    };

    //Fetch available sessions
    const fetchData = async () => {
        try {
            const response = await axios.get(`/company/agent/${agentData.id_agent}/session`,);
            if (response.error) {
                toast.error(response.error);
            } else {
                //console.log(response.data)
                setSessions(response.data)
            }
        } catch (error) {
            console.error('Error fetching data.', error);
        }
    };

    //Fetch sessions at load
    useEffect(() => {

        if (agentData.id_agent) fetchData();

        // eslint-disable-next-line
    }, [agentData]);

    /**
     * SESSIONS OVERVIEW TABLE LABEL    
     * @returns Label component
     */
    const Label = () => {

        return (
            <Grid container mt={2} sx={{ paddingLeft: 3, paddingRight: 3, marginBottom: 1 }} alignItems={'center'}>
                <Grid item xs={1.5}>
                    <Typography variant="body2" fontWeight='300'>Session ID</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body2" fontWeight='300'>Tags</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body2" fontWeight='300'>Cost</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body2" fontWeight='300'>Opened</Typography>
                </Grid>
                <Grid item xs={1.5}>
                    <Typography variant="body2" fontWeight='300'>Last Modified</Typography>
                </Grid>
                <Grid item xs={3} pl={6}>
                    <Typography variant="body2" fontWeight='300'>Actions</Typography>
                </Grid>
            </Grid>
        )
    }


    //Add new session
    const handleAddNew = async () => {

        try {
            const { data } = await axios.post(`/company/agent/${agentData.id_agent}/session`);
            if (data.error) {
                toast.error(data.error);
            } else {
                console.log(data)
                const link = `${window.location.origin}/share/session/${data.session.public_key}`
                await navigator.clipboard.writeText(link); //copy to clipboard
                fetchData(); //Fetch the sessions including the new one
                setTooltip(true); //Display copied to clipboard tooltip
            }
        } catch (error) {
            console.error('Error copying link to clipboard:', error);
        }
    }

    //Item connector for expandable list component - matching parameters
    const SessionItemConnector = ({ item }) => {
        return (<SessionItem
            key={item.id_session}
            id={item.id_session}
            cost={item.cost}
            modificationDate={timeAgo(item.last_modified)}
            opened={prettyDate(item.opened)}
            tags={item.tags}
            isTicket={item.is_ticket}
            tuneStatus={item.finetune}
            public_key={item.public_key}
        ></SessionItem>)
    }

    /**
     * FOOTER HELPER DESCRIPTION
     * @returns Footer description component
     */
    const Description = () => {
        return (
            <Grid item xs={12} mt={4}>
                <Typography variant='body2' fontWeight={300} align='justify' color={'#807d7d'}>
                    Sessions give you an overview of your currently open user interactions. You are able to reference each session by its session ID or by clicking on the share button. The tags show you the current status of the session - click here to learn more about status tags. To see the details and the full interaction click on “Open” at the end of the session bar. To assign a session content for fine tuning click on the brain symbol.
                </Typography>
            </Grid>
        )
    }

    return (
        <div>


            {/* HEADER */}
            <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                <Grid item xs={5.5}>
                    <Typography variant='h2' align="left">Sessions</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant='body2' fontWeight={300} align='right' color={'#807d7d'}>
                            When clicking the Add New button a new public session will be created.
                            The link will be copied to your clipboard, you can share it with your client.
                    </Typography>
                </Grid>
                <Grid item xs={1.5} container justifyContent="flex-end" alignItems="bottom">
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                        <Tooltip
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="Copied to clipboard"
                        >
                            <Button variant="contained" size="large" onClick={handleAddNew}>Add New</Button>
                        </Tooltip>
                    </ClickAwayListener>
                </Grid>
            </Grid>

            {/* TABLE LABEL*/}
            <Label />

            {/*EXPANDABLE SESSIONS LIST*/}
            <ExpandableList list={sessions} componentType={SessionItemConnector} />

            {/*FOOTER DESCRIPTION*/}
            <Description/>

        </div>
    )
}
