import { AppBar, Container, Grid, Toolbar, Typography } from '@mui/material'
import React from 'react'
import SessionsForm from '../components/SessionsForm'

/**
 * Component for viewing a session from a public perspective
 * @returns 
 */
export default function PublicMessage() {
    return (
        <>
            <AppBar position="static" elevation={3}>
                <Toolbar>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        AIBRAHAM
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container style={{ paddingTop: '1em', height: '100%' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} container minHeight="85vh" sx={{ display: 'flex', flexDirection: 'column', alignContent: 'space-between' }}>
                        <SessionsForm isGuest={true} />
                    </Grid>
                    <Grid item>
                        <Typography variant='body2'>Provided by Aibraham. By using this chat you accept our Privacy Policy.</Typography>
                    </Grid>
                </Grid>

            </Container>
        </>
    )
}
