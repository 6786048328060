import React, { useContext, useState } from 'react'
import { Box, Button, Divider, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import useAuth from '../hooks/useAuth'
import { Link } from 'react-router-dom';
import AgentDropdown from './AgentDropdown';
import { AgentContext } from '../context/AgentContext';

/**
 * VERTICAL AGENT NAVIGATION FOR THE APPLICATION
 * @returns Vertical menu component
 */
export default function VerticalMenu() {

    //Selected agent page
    const [selectedIndex, setSelectedIndex] = useState(-1);

    //User status
    const user = useAuth();

    //Agent status
    const agentContext = useContext(AgentContext);
    const { agentData } = agentContext;

    //Handle page change
    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    return (
        <Box
            height="90%"
            width="100%"
            pt={6}
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
        >

            <List>

                {/* AGENT PICKET */}
                <AgentDropdown></AgentDropdown>

                {/* HOME */}
                <ListItemButton button="true"
                    selected={selectedIndex === 0}
                    component={Link}
                    to="/home"
                    onClick={(event) => handleListItemClick(event, 0)}
                >
                    <ListItemText primary="Home" />
                </ListItemButton>

                {/* RESTRICTED FOR SELECTED AGENT */}
                {agentData && agentData.id_agent && <>
                    <ListItemButton button="true"
                        selected={selectedIndex === 1}
                        component={Link}
                        to="/tickets"
                        onClick={(event) => handleListItemClick(event, 1)}
                    >
                        <ListItemText primary="Ticket" />
                    </ListItemButton>
                    <ListItemButton button="true"
                        selected={selectedIndex === 2}
                        component={Link}
                        to="/sessions"
                        onClick={(event) => handleListItemClick(event, 2)}
                    >
                        <ListItemText primary="Sessions" />
                    </ListItemButton>
                    <ListItemButton button="true"
                        selected={selectedIndex === 3}
                        component={Link}
                        to="/knowledge"
                        onClick={(event) => handleListItemClick(event, 3)}
                    >
                        <ListItemText primary="Knowledge" />
                    </ListItemButton>
                    <ListItemButton button="true"
                        selected={selectedIndex === 4}
                        component={Link}
                        to="/finetune"
                        onClick={(event) => handleListItemClick(event, 4)}
                    >
                        <ListItemText primary="Fine tune" />
                    </ListItemButton>
                    <ListItemButton button="true"
                        component={Link}
                        to="/settings"
                        selected={selectedIndex === 5}
                        onClick={(event) => handleListItemClick(event, 5)}
                    >
                        <ListItemText primary="Settings" />
                    </ListItemButton>
                </>}

                {/* ONLY AVAILABLE FOR SUPERVISOR */}
                {
                    user.isSupervisor &&
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                }
                {
                    user.isSupervisor && (
                        <ListItemButton button="true"
                            selected={selectedIndex === 6}
                            component={Link}
                            to="/billing"
                            onClick={(event) => handleListItemClick(event, 6)}
                        >
                            <ListItemText primary="Billing" />
                        </ListItemButton>
                    )
                }
                {
                    user.isSupervisor && (
                        <ListItemButton
                            button="true"
                            component={Link}
                            to="/managers"
                            selected={selectedIndex === 7}
                            onClick={(event) => handleListItemClick(event, 7)}
                        >
                            <ListItemText primary="Managers" />
                        </ListItemButton>
                    )
                }
            </List>

            {/* FURTHER LINKS */}
            <List sx={{ paddingLeft: 2 }}>
                <ListItemText>
                    <Button variant='outlined' href={'/start'}>
                        Start guide
                    </Button>
                </ListItemText>
                <ListItemText style={{ marginBottom: '2em' }}>
                    <Button variant='outlined' href={'/documentation'}>
                        Integration
                    </Button>
                </ListItemText>
                <ListItemText>
                    <Typography variant='body2' fontWeight={200}>Report</Typography>
                </ListItemText>
                <ListItemText>
                    <Typography variant='body2' fontWeight={200}>Fraud protection</Typography>
                </ListItemText>
                <ListItemText>
                    <Typography variant='body2' fontWeight={200}><a style={{ color: 'white', textDecoration: 'none' }} href='/documentation'>Documentation</a></Typography>
                </ListItemText>

                {/* FOOTER */}
                <Typography variant='body2' fontWeight={200} mt={3}>
                    © 2024 Aibraham | Benedek Molnár
                </Typography>
            </List>

        </Box>
    )
}
