import React, { useContext, useEffect, useState } from 'react';
import { Typography, Button, Grid, Paper, Tooltip, IconButton, ClickAwayListener, Menu, MenuItem } from '@mui/material';
import { AgentContext } from '../context/AgentContext';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import axios from 'axios';
import { prettyDate, timeAgo } from '../helper/Time';
import CircleIcon from '@mui/icons-material/Circle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import ExpandableList from '../components/ExpandableList';

/**
 * TICKET PAGE COMPONENT
 * 
 * Returns the overview page for tickets.
 * 
 * @returns Ticket page
 */
export default function Tickets() {

    const navigate = useNavigate();
    const agentContext = useContext(AgentContext);
    const { agentData } = agentContext;

    //Available ticketed sessions
    const [sessions, setSessions] = useState([]);

    /**
     * TICKET TABLE ITEM COMPONENT
     * 
     * Represents one ticket item in the table 
     * 
     * @param {object} params Component parameters
     * @param {Number} params.id Ticketed session ID
     * @param {object} params.tags Session tags
     * @param {datetime} params.modificationDate Date of last modification
     * @param {String} params.publicKey Public key of the session
     * @returns One ticket table item component
     */
    const TicketItem = ({ id, tags, modificationDate, publicKey}) => {

        //Parsed tags
        const tagList = JSON.parse(tags);

        //Tooltip status
        const [tooltip, setTooltip] = useState(false);

        //** QUICKACTION MENU HANDLERS BEGIN
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);

        const handleMenu = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleMenuClose = () => {
            setAnchorEl(null);
        };
        
        const handleClick = () => {
            navigate(`/ticket/${id}`);
        }
        //** QUICKACTION MENU HANDLERS END

        //Open tooltip
        const handleTooltipOpen = async () => {
            const link = `${window.location.origin}/share/session/${publicKey}`
            try {
                await navigator.clipboard.writeText(link);
                setTooltip(true);
            } catch (error) {
                console.error('Error copying link to clipboard:', error);
            }
        };

        //Close tooltip
        const handleTooltipClose = () => {
            setTooltip(false);
        };

        //Delete session
        const onDelete = async () => {
            try {
                const response = await axios.delete(`/company/agent/${agentData.id_agent}/session/${id}`);
                if (response.error) {
                    toast.error(response.error);
                } else {
                    toast.success("Action successful.")
                    window.location.reload();
                }
            } catch (error) {
                console.error(error);

            }
        }

        return (
            <Paper elevation={3} style={{ borderRadius: 8, width: '100%', marginBottom: 8 }}>
                <Grid container sx={{ padding: 1, paddingLeft: 3, paddingRight: 3 }} alignItems={'center'}>

                    {/* INFORMATION */}
                    <Grid item xs={3}>
                        <Typography variant="body1" >{id}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        {tagList.map((tag, index) => (
                            <Tooltip title={tag.name} key={index} >
                                <CircleIcon fontSize='small' style={{ color: tag.color }} />
                            </Tooltip>
                        ))}
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body1" >{modificationDate}</Typography>
                    </Grid>

                    {/* QUICK ACTIONS */}
                    <Grid item xs={3} container justifyContent={'flex-end'}>
                        <ClickAwayListener onClickAway={handleTooltipClose}>
                            <Tooltip
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                onClose={handleTooltipClose}
                                open={tooltip}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title="Copied to clipboard"
                            >
                                <IconButton onClick={() => handleTooltipOpen(id)}>
                                    <ShareIcon />
                                </IconButton>
                            </Tooltip>
                        </ClickAwayListener>
                        <IconButton onClick={handleMenu}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleMenuClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={onDelete}>Delete</MenuItem>
                        </Menu>
                        <Button variant="outlined" style={{ marginLeft: '1em' }} onClick={handleClick}>
                            Open
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        );
    }

    //Fetch available tickets
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/company/agent/${agentData.id_agent}/tickets`,);
                if (response.error) {
                    toast.error(response.error);
                } else {
                    //console.log(response.data)
                    setSessions(response.data)
                }
            } catch (error) {
                console.error('Error fetching data.', error);
            }
        };

        if (agentData.id_agent) fetchData();

        // eslint-disable-next-line
    }, [agentData]);

    /**
     * TICKET TABLE LABEL
     * @returns Ticket table label component
     */
    const Label = () => {

        return (
            <Grid container mt={2} sx={{ paddingLeft: 3, paddingRight: 3 }} alignItems={'center'} pb={2}>
                <Grid item xs={3}>
                    <Typography variant="body2" fontWeight='300'>Session ID</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body2" fontWeight='300'>Tags</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body2" fontWeight='300'>Last Modified</Typography>
                </Grid>
                <Grid item xs={3} pl={6}>
                    <Typography variant="body2" fontWeight='300'>Actions</Typography>
                </Grid>
            </Grid>
        )
    }

    //Session item connector for expandable list - matching parameters
    const SessionItemConnector = ({ item }) => {
        return (<TicketItem
            key={item.id_session}
            id={item.id_session}
            cost={item.cost}
            modificationDate={timeAgo(item.last_modified)}
            opened={prettyDate(item.opened)}
            tags={item.tags}
            isTicket={item.is_ticket}
            tuneStatus={item.finetune}
            publicKey={item.public_key}
        ></TicketItem>)
    }

    /**
     * FOOTER HELPER DESCRIPTION
     * @returns Footer description component
     */
    const Description = () => {
        return (
            <Grid item xs={12} mt={4}>
                <Typography variant='body2' fontWeight={300} align='justify' color={'#807d7d'}>
                Sessions give you an overview of your currently open user interactions. You are able to reference each session by its session ID or by clicking on the share button. The tags show you the current status of the session - click here to learn more about status tags. To see the details and the full interaction click on “Open” at the end of the session bar. To assign a session content for fine tuning click on the brain symbol. 
                </Typography>
            </Grid>
        )
    }

    return (
        <div>

            <Typography variant='h2' align="left">Tickets</Typography>

            <Label />

            <ExpandableList list={sessions} componentType={SessionItemConnector} />

            <Description/>

        </div>
    )
}
