import { AppBar, Box, Button, Container, Grid, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import ChatWindow from '../components/ChatWindow';

export default function Propagation() {

    const pages = [
        { name: 'Home', reference: '#home' },
        { name: 'Aibraham', reference: '#aibraham' },
        { name: 'Benefits', reference: '#benefits' },
        { name: 'Features', reference: '#features' },
        { name: 'Contacts', reference: '#contacts' },
    ]

    const homeRef = useRef(null);
    const aibrahamRef = useRef(null);
    const benefitsRef = useRef(null);
    const featuresRef = useRef(null);
    const contactsRef = useRef(null);

    const MenuBar = () => {

        const [anchorElNav, setAnchorElNav] = useState(null);

        const handleOpenNavMenu = (event) => {
            setAnchorElNav(event.currentTarget);
        };

        const handleCloseNavMenu = () => {
            setAnchorElNav(null);
        };


        return (
            <AppBar position="sticky">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            AIBRAHAM
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                                        <Button href={page.reference}
                                            sx={{ color: 'white', display: 'block' }}>
                                            {page.name}
                                        </Button>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            AIBRAHAM
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
                            {pages.map((page) => (
                                <Button
                                    key={page}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                    href={page.reference}
                                >
                                    {page.name}
                                </Button>
                            ))}
                            <Button
                                sx={{ my: 2, ml: 2, color: 'white', display: 'block' }}
                                href='/login'
                                variant='outlined'
                            >
                                Login
                            </Button>
                            <Button
                                sx={{ my: 2, ml: 2, color: 'black', display: 'block', backgroundColor: orangeAccent }}
                                href='/register'
                                variant='outlined'
                            >
                                Register
                            </Button>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        );
    }

    const orangeAccent = '#E7B812'
    const grayLight = '#191919'
    return (
        <div>
            <MenuBar />
            <ChatWindow agentKey={"U+f/hc+K0GZBr/QfE9PBwg=="}></ChatWindow>
            <Grid container ref={homeRef} id="home" alignItems={'center'} justifyItems={'center'} minHeight={'90vh'} sx={{ backgroundImage: `url(propagation-web-bg-1.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
                <Container>
                    <Grid item xs={12} container alignItems={'center'} flexDirection={'column'}>
                        <Typography variant="body2" sx={{ flexGrow: 1, textTransform: 'uppercase' }} textAlign={'center'}>
                            Sales and customer service
                        </Typography>
                        <Typography variant="h2" sx={{ flexGrow: 1, textTransform: 'uppercase', fontSize: {xs:'2.5em', md:'3em'} }} textAlign={'center'}>
                            Elevate your business
                        </Typography>
                        <Typography variant="body1" fontSize={'2em'} sx={{ flexGrow: 1, textTransform: 'uppercase', fontSize: {xs:'1em', md:'1.5em'} }} textAlign={'center'}>
                            With our human like AI agents
                        </Typography>
                        <Button variant='contained' sx={{ marginTop: 2, backgroundColor: orangeAccent }} href={'#aibraham'} size='large'>
                            Show me
                        </Button>
                    </Grid>
                </Container>
            </Grid>

            <Grid container minHeight={'25vh'} sx={{ backgroundColor: grayLight }} pt={4} pb={4} alignItems={'center'}>
                <Container>
                    <Grid container alignItems={'center'}>
                        <Grid item lg={5}>
                            <Typography  variant="body1" fontSize={'2em'} sx={{ flexGrow: 1, textTransform: 'uppercase' }}>
                                Trusted by
                            </Typography>
                        </Grid>
                        <Grid item lg={7} container alignItems={'center'}>
                            <Grid item lg={4} container alignItems={'center'} justifyContent={'center'}>
                                <img src='promo-logo-1.jpg' style={{ maxWidth: '100%' }}></img>
                            </Grid>
                            <Grid item lg={4} container alignItems={'center'} justifyContent={'center'}>
                                <img src='promo-logo-2.jpg' style={{ maxWidth: '100%' }}></img>
                            </Grid>
                            <Grid item lg={4} container alignItems={'center'} justifyContent={'center'}>
                                <img src='promo-logo-3.jpg' style={{ maxWidth: '100%' }}></img>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Grid ref={aibrahamRef} id="aibraham" container minHeight={'90vh'}>
                <Grid item lg={6} minHeight='350px' container mt={{xs:0, md:4}} mb={4} sx={{ backgroundImage: `url(web-sessions.jpg)`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: {xs:'center center', md: 'right center'} }}>

                </Grid>
                <Grid item lg={6} pl={8} pr={{xs:8, md: 12}} pb={8} container flexDirection={'column'} justifyContent={'center'} >
                    <Typography variant="body1" fontSize={'2em'} sx={{ textTransform: 'uppercase' }} >
                        Human like live support
                    </Typography>
                    <Typography variant="body2" textAlign={'justify'} fontWeight={200}>
                        Tired of having those useless pre-programmed chatbots? Here is the solution! We provide agents just as real as your assistants. You provide the information, we do the rest. Our agents gives precise information spiced with a bit of emotional support either in sales or customer support.
                    </Typography>
                    <Button variant='contained' href='#benefits' sx={{ marginTop: 2, backgroundColor: orangeAccent, maxWidth: {md: '40%'} }} size='large'>
                        Show me
                    </Button>

                </Grid>
            </Grid>
            <Grid ref={benefitsRef} p={4} id="benefits" container minHeight={'40vh'} sx={{ backgroundColor: orangeAccent }} >
                <Container>
                    <Grid container alignContent={'center'} height={'100%'} spacing={3}>
                        <Grid item lg={4}>
                            <Typography variant='h2' fontSize={'2em'} color={'black'}>
                                0-24 Availability
                            </Typography>
                            <Typography variant="body2" textAlign={'justify'} fontWeight={200} color={'black'}>
                                With no extra charges our agents are available at all time ready to fulfill their duty. For them it is one whether you are located in the US or in EU, we can give you a full coverage on any continent. By making your agents time and location free, we are able to improve the quality of your services and save you extra costs.
                            </Typography>
                        </Grid>
                        <Grid item lg={4}>
                            <Typography variant='h2' fontSize={'2em'} color={'black'}>
                                Sales and support
                            </Typography>
                            <Typography variant="body2" textAlign={'justify'} fontWeight={200} color={'black'}>
                                Selling never was easier. Generate leads with our salesman who are trained just as the professionals. However we not only provide sales agents, but professionals for customer support too. It is either internal or external support, you just have to provide the context for the agents and you are ready to go.
                            </Typography>
                        </Grid>
                        <Grid item lg={4}>
                            <Typography variant='h2' fontSize={'2em'} color={'black'}>
                                Built on GPT
                            </Typography>
                            <Typography variant="body2" textAlign={'justify'} fontWeight={200} color={'black'}>
                                We built our agents on the industry leading OpenAI GPT models so we could provide you the best tools and for your customers the best experience. Forget the restricted chatbots and enter a new world with our conversational based agents who are ready to sell your product or to assist it.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Grid ref={featuresRef} id="features" container minHeight={'90vh'} pt={{xs:0, md:4}} pb={{xs:4, md:4}} flexDirection={{xs:'column-reverse', md:'row'}}>

                <Grid item lg={6} px={{xs:4, md:8}} container flexDirection={'row'} alignContent={'center'} >
                    <Grid item xs={12} >
                        <Typography variant="body1" fontSize={'2em'} sx={{ textTransform: 'uppercase' }} >
                            Trainable
                        </Typography>
                        <Typography variant="body2" textAlign={'justify'} fontWeight={200}>
                            Train your own agents the way you want them. Our agents are fully customizable and open to learn everything about your business. You just need to provide the information you would like them to use. You can include contract details, policies, requirements, licence information, product details, etc.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <Typography variant="body1" fontSize={'2em'} sx={{ textTransform: 'uppercase' }} >
                            Easy to manage
                        </Typography>
                        <Typography variant="body2" textAlign={'justify'} fontWeight={200}>
                            The application is designed to be user friendly even for the non IT managers. With our simple interface you are able to easily overview your agents and their work, or adjust them just with a few clicks. If something is not straight forward, we have a rich documentation prepared to help. But you can always use our supporting agent built into the site.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <Typography variant="body1" fontSize={'2em'} sx={{ textTransform: 'uppercase' }} >
                            No code solution
                        </Typography>
                        <Typography variant="body2" textAlign={'justify'} fontWeight={200}>
                            We know only a few have  interest in coding. This is why we brought to you a simple n-code solution. To use our agents just install the pre-prepared package, set up the agent and you are ready to go. With this out of the box solution you will be ready to start your first agent within minutes.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <Button href='#contacts' variant='contained' sx={{ marginTop: 2, backgroundColor: orangeAccent, maxWidth: '40%', marginRight: 2 }} size='large'>
                            Learn more
                        </Button>
                        <Button href='/login' variant='outlined' sx={{ marginTop: 2, maxWidth: '40%' }} size='large'>
                            Application
                        </Button>
                    </Grid>


                </Grid>
                <Grid item lg={6} minHeight='350px' mt={{xs:0, md: 0}} mb={{xs:4, md:0}} container sx={{ backgroundImage: `url(web-finetune.jpg)`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: {xs:'center center', md: 'left center'} }}>

                </Grid>
            </Grid>
            <Grid ref={contactsRef} id="contacts" container minHeight={'90vh'} flexDirection={'row'}>
                <Grid item xs={12} lg={5} minHeight='350px' container my={{md:4}} sx={{ backgroundImage: `url(web-sessions-list.jpg)`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: {xs:'center center', md: 'right center'} }}>

                </Grid>
                <Grid item xs={12} lg={3} minHeight='350px' sx={{ backgroundColor: orangeAccent }} ml={{xs:0, md: '-15vw'}} my={{md:'15vh'}}>

                </Grid>
                <Grid item xs={12} lg={4} p={{xs:4, lg:0}} ml={{md: 8}} container flexDirection={'row'} alignContent={'center'}>
                    <Grid item xs={12}>
                        <Typography variant="body1" fontSize={'2em'} sx={{ textTransform: 'uppercase' }} >
                            PERFECT FOR ALL SCALE
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body2" textAlign={'justify'} fontWeight={200}>
                            You are important for us! Thats why the agents are designed for every size of business. We believe that business automation should be available for everybody. But the best if you try it for yourself. Please dont hesitate to get in touch with us for a free demo or a custom pricing.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant='contained' sx={{ marginTop: 2, backgroundColor: orangeAccent, maxWidth: '40%', marginRight: 2 }} size='large'>
                            Register
                        </Button>
                        <Button variant='outlined' sx={{ marginTop: 2, maxWidth: '40%' }} size='large'>
                            Documentation
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
