import { Button, Grid, TextField, Typography } from '@mui/material'
import axios from 'axios';
import React, { useState } from 'react'
import useAuth from '../hooks/useAuth';
import toast from 'react-hot-toast';

/**
 * MANAGER USER PROFILE
 * 
 * Contains all user profile settings
 * Like reset-password
 * @returns user profile component
 */
export default function Profile() {

    const [password, setPassword] = useState({ password: '', newPassword: '' });
    const { name, email, id } = useAuth();

    //Submit reset password
    const handleSubmit = async () => {
        try {
            const { data } = await axios.put(`/accounts/user/${id}`, password);
            if (data.error) {
                toast.error(data.error);
            } else {
                setPassword({ password: '', newPassword: '' });
                toast.success('Settings saved.');
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                <Grid item xs={6}>
                    <Typography variant='h2' align="left">Profile</Typography>
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end" alignItems="bottom">
                    <Button variant="contained" size="large" onClick={handleSubmit}>Save</Button>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6} container >
                    <Grid item mt={2} xs={12}>
                        <Typography variant='h6' fontWeight={'300'}><b>User:</b> {name}</Typography>
                        <Typography variant='h6' fontWeight={'300'}><b>Email:</b> {email}</Typography>
                    </Grid>
                    {/*Reset password form */}
                    <Grid item mt={2} xs={12}>
                        <Typography variant='h6' mb={1}>Change password</Typography>
                        <form>
                            <TextField
                                sx={{ marginBottom: 1 }}
                                fullWidth
                                label="Type in your old password"
                                variant="outlined"
                                value={password.password}
                                inputProps={{ minLength: 6 }}
                                onChange={(e) => setPassword({ ...password, password: e.target.value })} />
                            <TextField
                                sx={{ marginBottom: 1 }}
                                fullWidth
                                label="Type in your new password"
                                variant="outlined"
                                value={password.newPassword}
                                inputProps={{ minLength: 6 }}
                                onChange={(e) => setPassword({ ...password, newPassword: e.target.value })} />
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
