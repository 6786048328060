import React, { useContext, useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { AgentContext } from '../context/AgentContext';
import axios from 'axios';
import toast from 'react-hot-toast';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

/**
 * AGENT SELECTOR - DROPDOWN 
 * 
 * @returns Agent selection dropdown component
 */
const AgentDropdown = () => {

  //Currently selected agent
  const [selectedAgent, setSelectedAgent] = useState('');

  //All available agents
  const [allAgents, setAllAgents] = useState([]);

  //Information about the agent
  const agentContext = useContext(AgentContext);
  const navigate = useNavigate();

  //Create new agent
  const handleChange = (event) => {
    const agentId = event.target.value;
    if (agentId === "new") {
      navigate(`/agent/add`);
    }
    else {
      agentContext.handleSetAgent(agentId);
    }
  };

  //Handle selected agent
  useEffect(() => {
    setSelectedAgent(agentContext.agent)
  }, [agentContext.agent])

  //Fetch available agents
  useEffect(() => {
    axios.get('/company/agents')
      .then(({ data }) => {
        if (data.error) {
          toast.error(data.error);
        } else {
          setAllAgents(data);
        }
      })
      .catch((error) => {
        toast.error('Could not load agents.');
      });
  }, [agentContext.agent])


  return (
  <div>

    {
      selectedAgent !== null &&
      (<FormControl fullWidth>
        <InputLabel id="agent-dropdown-label">Select an agent</InputLabel>
        <Select
          labelId="agent-dropdown-label"
          id="agent-dropdown"
          value={selectedAgent}
          label="Select an agent"
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {allAgents.map((agent) => (
            <MenuItem key={agent.id_agent} value={agent.id_agent}>
              {agent.name} - {Boolean(agent.is_active) ? 'Active':'Offline'/*{agent.fineTuned ? 'Fine tuned' : 'Not fine tuned'}*/}
            </MenuItem>
          ))}
          <MenuItem value="new">
            <Button startIcon={<AddIcon />}>Add new</Button>
          </MenuItem>
        </Select>

      </FormControl>)
    }
  </div>
  );
};

export default AgentDropdown;