import { Grid, ListItemIcon, Paper, Typography } from '@mui/material'
import React, { useContext, useState } from 'react';
import { TextField, Button, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { AgentContext } from '../context/AgentContext';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

/**
 * ADD AGENT PAGE
 * 
 * Rerturns the agent add form with its information
 * 
 * @returns Add agent page
 */
export default function AddAgent() {

    const navigate = useNavigate();
    const agentContext = useContext(AgentContext);

    //Available agent programs
    const types = [
        { value: 0, label: 'Sales', icon: <AttachMoneyIcon /> },
        { value: 1, label: 'Support', icon: <SupportAgentIcon /> },
    ]

    //Form data
    const [formData, setFormData] = useState({
        name: '',
        type: 0,
    });

    //Handle form data
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    //Add new agent
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const { data } = await axios.post(`/company/agents`, formData)

            if (data.error) {
                toast.error(data.error);
            } else {
                setFormData({
                    name: '',
                    type: 0
                });
                console.log(data)
                agentContext.handleSetAgent(data.agentId);
                navigate('/home');
                toast.success('Agent added.');
            }
        } catch (error) {
            console.log(error);
        }

    };

    return (
        <>
            <Typography variant='h2'>Add new agent</Typography>

            {/* FIRST COLUMN - FORM */}
            <Grid container mt={4} spacing={2}>
                <Grid item xs={6}>
                    <Paper elevation={3} sx={{ padding: 5 }}>
                        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                            <TextField
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                label="Name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />

                            <TextField
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                select
                                label="Choose type"
                                name="type"
                                value={formData.type}
                                onChange={handleChange}
                            >
                                {types.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        <ListItemIcon>{option.icon}</ListItemIcon>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Button sx={{ marginTop: 2 }} type="submit" variant="contained" color="primary" size='large'>
                                Create
                            </Button>
                        </form>
                    </Paper>
                </Grid>

                {/* SECOND COLUMN - HELP*/}
                <Grid item xs={6}>
                    <Paper elevation={3} sx={{ padding: '2em', height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Typography variant='h5'>Need help?</Typography>
                        <Typography varaint='body2'>Agents are AI assistants who will support your business based on the data you provide.</Typography>

                        <Typography variant='h6'>Sales agent</Typography>
                        <Typography varaint='body2'>Well-restricted agent to sell and provide support with the sale on a public domain.</Typography>

                        <Typography variant='h6'>Support agent</Typography>
                        <Typography varaint='body2'>Open agent trained to provide support and assist with the procedures on a private domain.</Typography>

                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}
