import './App.css';
import { CssBaseline} from '@mui/material';
import { Routes, Route } from 'react-router-dom'

import axios from 'axios';

import AdminApp from './structures/AdminApp';
import PublicMessage from './structures/PublicMessage';
import Propagation from './pages/Propagation';
import ApiDocumentation from './pages/ApiDocumentation';
import Documentation from './pages/Documentation';

axios.defaults.baseURL = 'https://aibraham.tech/api'//'http://localhost:8000/api'
axios.defaults.withCredentials = true

function App() {


  return (
    <div style={{height: '100%'}}>
      <CssBaseline />
      <Routes>
        <Route path='/share/session/:id' element={<PublicMessage />} />
        <Route path='*' element={<AdminApp />} />
        <Route path='/' element={<Propagation />} />
        <Route path='/documentation/api' element={<ApiDocumentation />} />
        <Route path='/documentation' element={<Documentation />} />
      </Routes>
    </div>
  );
}

export default App;
