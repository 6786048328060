import React, { useContext, useEffect, useRef, useState } from 'react'
import { Box, TextField, Button, Typography, Divider, Select, MenuItem, Chip, IconButton, Menu, Grid, LinearProgress } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { AgentContext } from '../context/AgentContext';

/**
 * SESSION VIEW 
 * 
 * Displays an universal view of a session including tags, messages.
 * 
 * @param {object} props Component props
 * @param {boolean} props.isGuest Defines whether the component is viewed by a guest or internal member 
 * @returns 
 */
export default function SessionsForm({ isGuest = false }) {

    //Definition of sender roles
    const SENDER = {
        agent: "assistant",
        user: "user"
    }

    const { id } = useParams();
    const navigate = useNavigate();
    const agentContext = useContext(AgentContext);
    const { agentData } = agentContext;

    //Is the session ticket?
    const [isTicket, setIsTicket] = useState(false);

    //Assigned tags
    const [tags, setTags] = useState([]);

    //Selected sender
    const [selectedOption, setSelectedOption] = useState(SENDER.user);

    //Fetching session meta-tags and isticket
    useEffect(() => {

        const fetch = async () => {
            try {
                const { data } = await axios.get(`/company/agent/${agentData.id_agent}/session/${id}`)
                if (data.error) {
                    toast.error(data.error);
                } else {
                    if (data.is_ticket)
                        setIsTicket(true)
                    //if (data.messages && data.messages.length > 0)
                    //    setMessages(data.messages);
                    if (data.tags)
                        setTags(JSON.parse(data.tags));
                }
            } catch (error) {
                console.log(error);
            }
        }

        if (id && !isGuest) fetch();
    }, [id])

    /**
     * SESSION TAGS LINE
     * 
     * Displays and handles the available tags on the session
     * @returns tagging component
     */
    const TagsSection = () => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [tagOptions, setTagOptions] = useState([]);

        useEffect(() => {

            const fetchData = async () => {
                try {
                    const { data } = await axios.get(`/public/session/tags`);
                    if (data.error) {
                        toast.error(data.error);
                    } else {
                        setTagOptions(data.tags);
                    }
                } catch (error) {
                    console.log(error);
                }
            };

            fetchData();
        }, []);

        const handleAddTag = (tag) => {
            setTags([...tags, tag]);
            setAnchorEl(null); // Close the menu after adding the tag
        };

        const handleDeleteTag = (tagIndex) => {
            setTags(tags.filter((_, index) => index !== tagIndex));
        };

        const handleMenuOpen = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleMenuClose = () => {
            setAnchorEl(null);
        };

        return (
            <Box mb mt>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {tags.map((tag, index) => (
                        <Chip
                            key={index}
                            label={tag.name}
                            onDelete={() => handleDeleteTag(index)}
                            sx={{ backgroundColor: tag.color }}
                        />
                    ))}
                    <IconButton onClick={handleMenuOpen}>
                        <AddIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        {tagOptions.map((tag, index) => (
                            <MenuItem key={index} onClick={() => handleAddTag(tag)}>{tag.name}</MenuItem>
                        ))}
                    </Menu>
                </Box>
            </Box>
        );
    };

    /**
     * MESSAGES BOX COMPONENT
     * 
     * Displays the messages in the correct position (left or right) based on the viewer(guest or manager)
     * Allows to send messages in case the session is a ticket or its viewed as guest
     * 
     * @returns message box in the correct view
     */
    const ChatWindow = ({ isReverse }) => {

        //New message input
        const [newMessage, setNewMessage] = useState('');
        const chatEndRef = useRef(null);

        //Is the user waiting for agent reply?
        const [isWaiting, setIsWaiting] = useState(false)

        //Messages with initial message
        const [messages, setMessages] = useState([{
            role: "assistant",
            type: "message",
            content: "Hi! Im Aibraham, how can I help you?"
        }]);

        /**
         * Check whether the user is waiting for answer
         */
        useEffect(() => {
            const message = messages.slice(-1);
            if (message && message.length > 0)
                if (message[0].role === "user")
                    setIsWaiting(true)
                else
                    setIsWaiting(false);
        }, [messages])


        const scrollToBottom = () => {
            chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        };

        /**
         * Fetching messages every 5 seconds based on the correct view
         */
        useEffect(() => {

            const fetchData = async () => {
                try {
                    const { data } = await axios.get(isGuest ? `/public/session/${id}` : `/company/agent/${agentData.id_agent}/session/${id}`);
                    if (data.error) {
                        toast.error(data.error);
                    } else {
                        console.log(data)
                        console.log(messages)
                        //if (data.isTicket)
                        //setIsTicket(true)
                        console.log(data.messages.length, messages.length)
                        if (data.messages.length !== messages.length){
                            setMessages(data.messages);
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            };

            if (id) {
                fetchData(); // Fetch data immediately on mount
                const intervalId = setInterval(fetchData, 5000); // Fetch data every 5 seconds
                return () => clearInterval(intervalId); // Cleanup function to clear interval on unmount
            }
        }, [id, messages]);

        const baseSide = isReverse ? SENDER.user : SENDER.agent;

        const handleMessageChange = (event) => {
            setNewMessage(event.target.value);
        };

        //Sending the message to the correct path with the correct parameters depending on the user
        const handleSendMessage = async () => {
            if (isGuest) {
                if (newMessage.trim() === '') return;

                try {
                    const { data } = await axios.post(`/public/session/${id}`, { newMessage: newMessage });
                    if (data.error) {
                        toast.error(data.error);
                    } else {
                        setMessages([...messages, { content: newMessage, role: 'user', type: 'message' }]);
                        setNewMessage('');
                    }
                } catch (error) {
                    console.log(error);
                }

            } else {
                if (newMessage.trim() !== '') {
                    try {
                        const { data } = await axios.post(`/company/ticket/${id}`, { role: selectedOption, content: newMessage })
                        if (data.error) {
                            toast.error(data.error);
                        } else {
                            setMessages([...messages, { content: newMessage, role: selectedOption, type: 'message' }]);
                            setNewMessage('');
                        }
                    } catch (error) {
                        console.log(error)
                    }

                }
            }
        };

        useEffect(() => {
            scrollToBottom();
            // eslint-disable-next-line
        }, [messages]);

        return (
            <Box
                sx={{
                    height: isGuest ? '85vh' : '500px',
                    maxHeight: isGuest ? '85vh' : '500px',
                    '@media (max-width:600px)': {
                        height: '500px',
                        maxHeight: '500px',
                    },
                    border: '1px solid #ccc',
                    borderRadius: 2,
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {/*Ticket label */}
                {isTicket && (
                    <Box
                        sx={{
                            backgroundColor: 'warning.main',
                            color: 'warning.contrastText',
                            textAlign: 'center',
                            padding: 1,
                        }}
                    >
                        This conversation is was assigned as Ticket and is managed by a human. Responses may take longer.
                    </Box>
                )}

                {/*Messages*/}
                <Box sx={{ flex: 1, padding: 2, overflowY: 'auto' }}>
                    {messages.map((message, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                flexDirection: message.role === baseSide ? 'row-reverse' : 'row',
                                alignItems: 'flex-start',
                                mb: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: message.role === baseSide ? 'primary.main' : '#f0f0f0',
                                    color: message.role === baseSide ? '#000' : '#000',
                                    borderRadius: '10px',
                                    py: 1,
                                    px: 2,
                                    maxWidth: '70%',
                                    wordWrap: 'break-word',
                                }}
                            >
                                <Typography variant="body1">{message.content}</Typography>
                            </Box>
                        </Box>
                    ))}
                    <div ref={chatEndRef} />
                </Box>

                {/*Input form */}
                {isWaiting && !isTicket && (
                    <Box sx={{ width: '100%', marginTop: 4, marginBottom: 4 }}>
                        <LinearProgress />
                    </Box>
                )}
                {
                    !isWaiting && (isTicket || isGuest) && (
                        <>
                            <Divider />
                            <Box sx={{ padding: 2, display: 'flex', alignItems: 'center', alignItems: "stretch" }}>
                                {!isGuest && (
                                    <Select
                                        value={selectedOption}
                                        onChange={(e) => setSelectedOption(e.target.value)}
                                        variant="outlined"
                                        sx={{ minWidth: 120, mr: 1 }}
                                    >
                                        <MenuItem value={SENDER.agent}>Manager</MenuItem>
                                        <MenuItem value={SENDER.user}>Client</MenuItem>
                                    </Select>
                                )}

                                <TextField
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    placeholder="Type your message..."
                                    value={newMessage}
                                    onChange={handleMessageChange}
                                    sx={{ flex: 1 }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSendMessage}
                                    sx={{ ml: 1 }}
                                >
                                    Send
                                </Button>
                            </Box>
                        </>)
                }
            </Box>
        );
    };

    //Submitting modifications, for example tags
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const { data } = await axios.put(`/company/agent/${agentData.id_agent}/session/${id}`, {
                tags: tags
            })
            if (data.error) {
                toast.error(data.error);
            } else {
                navigate('/sessions')
                toast.success('Session updated.');
            }
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <>
            <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                <Grid item xs={9}>
                    {!isGuest && (
                        <Typography variant='h2' align="left">
                            {isTicket ? `Ticket #${id}` : `Session #${id}`}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end" alignItems="bottom">
                    {!isGuest &&
                        <Button variant="contained" size="large" onClick={handleSubmit} sx={{ marginTop: 2 }}>
                            Save
                        </Button>}
                </Grid>
            </Grid>
            {!isGuest && <TagsSection />}
            <ChatWindow isReverse={isGuest}></ChatWindow>
        </>
    )
}
