import React, { useContext, useState } from 'react';
import { Paper, Typography, IconButton, Button, Grid, Tooltip, ClickAwayListener, Menu, MenuItem } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ShareIcon from '@mui/icons-material/Share';
import { AgentContext } from '../context/AgentContext';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useTheme } from '@emotion/react';
import IosShareIcon from '@mui/icons-material/IosShare';


/**
 * SESSION ITEM COMPONENT FOR SESSIONS TABLE
 * 
 * @param {object} params Component parameters 
 * @param {Number} params.id Component parameters 
 * @param {object} params.tags Component parameters 
 * @param {Number} params.cost Component parameters 
 * @param {datetime} params.opened Component parameters 
 * @param {datetime} params.modificationDate Component parameters 
 * @param {Boolean} params.isTicket Component parameters 
 * @param {Number} params.tuneStatus Component parameters 
 * @param {String} params.public_key Component parameters 
 * @returns One session item component
 */
export default function SessionItem({ id, tags, cost, opened, modificationDate, isTicket, tuneStatus, public_key }) {

    //Parsed taglist
    const tagList = JSON.parse(tags);

    //Quickactions menu anchor
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const theme = useTheme();
    const navigate = useNavigate();

    //Agent context
    const agentContext = useContext(AgentContext);
    const { agentData } = agentContext;

    //Share tooltip status
    const [tooltip, setTooltip] = useState(false);

    //Handle menu
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    //Open session
    const handleClick = () => {
        navigate(isTicket ? `/ticket/${id}` : `/sessions/${id}`);
    }

    //Handle share tooltip open
    const handleTooltipOpen = async (sessionId) => {
        const link = `${window.location.origin}/share/session/${public_key}`
        if (!sessionId) return;
        try {
            await navigator.clipboard.writeText(link); // copy public link to clipboard
            setTooltip(true);
        } catch (error) {
            console.error('Error copying link to clipboard:', error);
        }
    };

    //Close share tooltip
    const handleTooltipClose = () => {
        setTooltip(false);
    };

    //Delete session
    const onDelete = async () => {
        try {
            const response = await axios.delete(`/company/agent/${agentData.id_agent}/session/${id}`);
            if (response.error) {
                toast.error(response.error);
            } else {
                toast.success("Action successful.")
                window.location.reload();
            }
        } catch (error) {
            console.error(error);

        }
    }

    //Handle assign to ticket quickaction
    const handleAssignTicket = async () => {
        try {
            if (isTicket) {
                toast.success("The session is already migrated.")
                return;
            }

            const response = await axios.put(`/company/agent/${agentData.id_agent}/session/${id}/ticket`, {});
            if (response.error) {
                toast.error(response.error);
            } else {
                toast.success("Session migrated as ticket.")
                window.location.reload();
            }
        } catch (error) {
            console.error(error);
        }
    }

    //Change tune status quickaction
    const handleTuneStatus = async () => {
        try {
            const changedStatus = Number(tuneStatus) === 0 ? 1 : 0;
            const response = await axios.put(`/company/agent/${agentData.id_agent}/session/${id}/finetune`, { tuneStatus: changedStatus });
            if (response.error) {
                toast.error(response.error);
            } else {
                toast.success("Session modified.")
                window.location.reload();
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Paper elevation={3} style={{ borderRadius: 8, width: '100%', marginBottom: 8 }}>
            <Grid container sx={{ padding: 1, paddingLeft: 3, paddingRight: 3 }} alignItems={'center'}>

                {/* INFORMATION */}
                <Grid item xs={1.5}>
                    <Typography variant="body1" >{id}</Typography>
                </Grid>
                <Grid item xs={2}>
                    {tagList.map((tag, index) => (
                        <Tooltip title={tag.name} key={index} >
                            <CircleIcon fontSize='small' style={{ color: tag.color }} />
                        </Tooltip>
                    ))}
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body1" >{Number(cost).toPrecision(3)}€</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body1" >{opened}</Typography>
                </Grid>
                <Grid item xs={1.5}>
                    <Typography variant="body1" >{modificationDate}</Typography>
                </Grid>


                {/* QUICKACTIONS */}
                <Grid item xs={3} container justifyContent={'flex-end'}>
                    <Tooltip title='Fine tune'>
                        <IconButton onClick={handleTuneStatus}>
                            <PsychologyIcon sx={tuneStatus ? { color: (theme) => theme.palette.orangeAccent.main } : {}} />
                        </IconButton>
                    </Tooltip>
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                        <Tooltip
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={tooltip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="Copied to clipboard"
                        >
                            <IconButton onClick={() => handleTooltipOpen(id)}>
                                <ShareIcon />
                            </IconButton>
                        </Tooltip>
                    </ClickAwayListener>
                    <Tooltip title='Migrate to ticket'>
                        <IconButton onClick={handleAssignTicket}>
                            <IosShareIcon sx={isTicket ? { color: (theme) => theme.palette.orangeAccent.main } : {}} />
                        </IconButton>
                    </Tooltip>
                    <IconButton onClick={handleMenu}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={onDelete}>Delete</MenuItem>
                    </Menu>
                    <Button variant="outlined" style={{ marginLeft: '1em' }} onClick={handleClick}>
                        Open
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
}
