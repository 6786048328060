import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthProvider from 'react-auth-kit';
import createStore from 'react-auth-kit/createStore';

const theme = createTheme({
  palette: {
    mode: 'dark', // Set the dark mode here
    default: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    orangeAccent:{
      main: '#E7B812',
    }
  },
  typography: {
    h2: {
      fontWeight: 400,
      textTransform: 'uppercase',
      letterSpacing: '2px',
    },
    button: {
      fontWeight: 300,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root')
);

const store = createStore({
  authName:'_auth',
  authType:'cookie',
  cookieDomain: window.location.hostname,
  cookieSecure: false,//window.location.protocol === 'https:'
});

root.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <AuthProvider store={store}>
        <Router>
          <App />
        </Router>
      </AuthProvider>
    </React.StrictMode>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
